import React, { useState } from "react";
import {
  Box,
  Button,
} from "@mui/material";
import useApiService from "../../../../api/apiService";
import { SelectAthlete, EventGroupPod } from "./_components";
import AreYouSure from "../../../../components/utils/areYouSure/areYouSure";
import { ComponentWrapper } from "../../../../components/utils";
import Form from "../../../../components/form/form";

const EventGroupSection = () => {
  const [openForm, setOpenForm] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [openAthleteDialog, setOpenAthleteDialog] = useState(false);
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const apiService = useApiService();

  const { data: eventGroups, refetch: refetchEventGroups } =
    apiService.useEventGroups();
  const { data: allAthletes } = apiService.useAthletes();
  const { mutate: createEventGroup } = apiService.useCreateEventGroup();
  const { mutate: updateEventGroup } = apiService.useUpdateEventGroup();
  const { mutate: deleteEventGroup } = apiService.useDeleteEventGroup();
  const { mutate: linkEventGroups } = apiService.useLinkEventGroups();
  const { mutate: unlinkEventGroups } = apiService.useUnlinkEventGroups();

  const handleCreateGroup = async (formData) => {
    createEventGroup(
      { name: formData.name },
      {
        onSuccess: () => {
          setOpenForm(false);
          refetchEventGroups();
        },
        onError: (error) => {
          console.error("Error creating event group:", error);
        },
      }
    );
  };

  const handleManageAthletes = (group) => {
    setSelectedGroup(group);
    setSelectedAthletes(group.athletes.map((athlete) => athlete.id));
    setOpenAthleteDialog(true);
  };

  const handleToggleAthlete = (athleteId) => {
    setSelectedAthletes((prev) =>
      prev.includes(athleteId)
        ? prev.filter((id) => id !== athleteId)
        : [...prev, athleteId]
    );
  };

  const handleSaveAthletes = async () => {
    const athletesToAdd = selectedAthletes.filter(
      (id) => !selectedGroup.athletes.some((a) => a.id === id)
    );
    const athletesToRemove = selectedGroup.athletes
      .filter((a) => !selectedAthletes.includes(a.id))
      .map((a) => a.id);

    if (athletesToAdd.length > 0) {
      athletesToAdd.forEach((athleteId) => {
        linkEventGroups({
          id: athleteId,
          data: { event_group_ids: [selectedGroup.id] },
        });
      });
    }

    if (athletesToRemove.length > 0) {
      athletesToRemove.forEach((athleteId) => {
        unlinkEventGroups({
          id: athleteId,
          data: { event_group_ids: [selectedGroup.id] },
        });
      });
    }

    setOpenAthleteDialog(false);
    refetchEventGroups();
  };

  const handleDeleteGroup = (groupId) => {
    setGroupToDelete(groupId);
    setOpenConfirmDelete(true);
  };

  const confirmDeleteGroup = async () => {
    deleteEventGroup(groupToDelete, {
      onSuccess: () => {
        setOpenConfirmDelete(false);
        refetchEventGroups();
      },
      onError: (error) => {
        console.error("Error deleting event group:", error);
      },
    });
  };

  const handleEditGroup = async (formData) => {
    updateEventGroup(
      { id: selectedGroup.id, data: { name: formData.name } },
      {
        onSuccess: () => {
          setOpenForm(false);
          refetchEventGroups();
        },
        onError: (error) => {
          console.error("Error updating event group:", error);
        },
      }
    );
  };

  const formFields = [
    { name: "name", label: "Group Name", type: "text", required: true },
  ];

  return (
    <ComponentWrapper>
      <Box display="flex" justifyContent="flex-end" mb={2} p={2}>
        <Button variant="contained" onClick={() => setOpenForm(true)}>
          Create New Training Group
        </Button>
      </Box>

      <Form
        open={openForm}
        onClose={() => setOpenForm(false)}
        fields={formFields}
        onSubmit={selectedGroup ? handleEditGroup : handleCreateGroup}
        title={selectedGroup ? "Edit Training Group" : "Create New Training Group"}
        defaultValues={selectedGroup ? { name: selectedGroup.name } : {}}
      />

      <Box mt={2} p={2}>
        {eventGroups &&
          eventGroups.map((group) => (
            <EventGroupPod
              key={group.id}
              group={group}
              onManageAthletes={handleManageAthletes}
              onDeleteGroup={handleDeleteGroup}
              onEditGroup={() => {
                setSelectedGroup(group);
                setOpenForm(true);
              }}
            />
          ))}
      </Box>

      <SelectAthlete
        open={openAthleteDialog}
        onClose={() => setOpenAthleteDialog(false)}
        selectedGroup={selectedGroup}
        allAthletes={allAthletes || []}
        selectedAthletes={selectedAthletes}
        onToggleAthlete={handleToggleAthlete}
        onSave={handleSaveAthletes}
      />

      <AreYouSure
        isOpen={openConfirmDelete}
        handleClose={() => setOpenConfirmDelete(false)}
        onConfirm={confirmDeleteGroup}
        itemName="Event Group"
      />
    </ComponentWrapper>
  );
};

export { EventGroupSection };
