import { Box, useMediaQuery, useTheme } from "@mui/material";
import PageWrapper from "../pageWrapper/pageWrapper";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { ComingSoon, CreateOrLogin, Counts, ExploreFeatures, UserCounts, Welcome, DownloadPWA } from "./_components";
import WeatherForecastHome from "../../components/weather/weatherForcastHome";
import SubTiers from "./_components/subTiers";

function Home() {
  const { token, team } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isPro = team?.subscription?.plan === "plan_pro_yearly" || team?.subscription?.plan === "plan_pro_monthly";

  return (
    <PageWrapper rootUrl="/home" sx={{ mt: !token && !isMobile ? 0 : !isMobile ? -10 : 3 }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: isMobile ? 10 : 3,
        padding: isMobile ? 1 : 4
      }}>
        {isMobile && <DownloadPWA />}
        {!token && <>
          <Welcome />
          <Counts />
          <ExploreFeatures />
          <UserCounts />
          <CreateOrLogin />
          <SubTiers />
          <ComingSoon />
        </>}
        {token && <>
          {isPro && <WeatherForecastHome />}
          <UserCounts />
          <ComingSoon />
          <SubTiers />
        </>}
      </Box>
    </PageWrapper>
  );
}

export default Home;
