import React from 'react';
import ModalWrapper from '../utils/wrappers/modalWrapper';

const DesktopModal = ({ open, onClose, children, sx = {} }) => {
  return (
    <ModalWrapper open={open} handleClose={onClose} sx={{ zIndex: 1301, ...sx, backdropFilter: 'blur(1px)' }}>
      {children}
    </ModalWrapper>
  );
};

export default DesktopModal;
