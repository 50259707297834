import React, { useState } from "react";
import {
  ListItemText,
  IconButton,
  Paper,
  Box,
  Menu,
  MenuItem,
  Divider,
  Button,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import useApiService from "../../../../api/apiService";
import WorkoutRepPod from "./workoutRepPod";
import { SetForm, RepForm } from "./forms";
import AreYouSure from "../../../../components/utils/areYouSure/areYouSure";

const WorkoutSetPod = ({ workoutId }) => {
  const {
    useWorkoutSets,
    useCreateWorkoutSet,
    useUpdateWorkoutSet,
    useDeleteWorkoutSet,
    useCreateWorkoutRep,
    queryClient,
  } = useApiService();
  const {
    data: workoutSets,
    isLoading: isLoadingWorkoutSets,
    isError: isErrorWorkoutSets,
  } = useWorkoutSets(workoutId);
  const [openSetDialog, setOpenSetDialog] = useState(false);
  const [openRepDialog, setOpenRepDialog] = useState(false);
  const [selectedSet, setSelectedSet] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const createWorkoutSetMutation = useCreateWorkoutSet(workoutId);
  const updateWorkoutSetMutation = useUpdateWorkoutSet(workoutId);
  const deleteWorkoutSetMutation = useDeleteWorkoutSet(workoutId);
  const createWorkoutRepMutation = useCreateWorkoutRep(
    workoutId,
    selectedSet?.id
  );

  const handleMenuClick = (event, setId) => {
    const set = workoutSets.find((set) => set.id === setId);
    setSelectedSet(set);
    setAnchorEl(event.currentTarget);
  };

  const handleClickOpenRep = (rep) => {
    setSelectedRep(rep);
    setOpenRepDialog(true);
  };

  const handleCloseSetDialog = () => {
    setOpenSetDialog(false);
    setSelectedSet(null);
  };

  const handleCloseRepDialog = () => {
    setOpenRepDialog(false);
    setSelectedRep(null);
  };

  const handleSaveSet = async (data) => {
    try {
      if (selectedSet) {
        await updateWorkoutSetMutation.mutateAsync({
          id: selectedSet.id,
          data,
        });
      } else {
        await createWorkoutSetMutation.mutateAsync(data);
      }
      handleCloseSetDialog();
    } catch (error) {
      console.error("Error saving set:", error);
    }
  };

  const handleSaveRep = async (data) => {
    try {
      await createWorkoutRepMutation.mutateAsync(data);
      handleCloseRepDialog();
    } catch (error) {
      console.error("Error saving rep:", error);
    }
  };

  const handleDeleteSet = async () => {
    try {
      await deleteWorkoutSetMutation.mutateAsync(selectedSet.id);
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Error deleting set:", error);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMoveSet = async (position, direction) => {
    const currentSet = workoutSets.find((set) => set.position === position);
    if (!currentSet) return;

    const targetPosition = direction === "up" ? position - 1 : position + 1;
    const targetSet = workoutSets.find(
      (set) => set.position === targetPosition
    );
    if (!targetSet) return;

    try {
      await updateWorkoutSetMutation.mutateAsync({
        id: currentSet.id,
        data: { position: targetSet.position },
      });
      setTimeout(() => {
        queryClient.invalidateQueries(["workoutSets", workoutId]);
      }, 300);
    } catch (error) {
      console.error("Error moving set:", error);
    }
  };

  const formatRecoveryTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    
    if (minutes === 0) {
      return `${seconds} seconds`;
    } else if (remainingSeconds === 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
  };

  if (isLoadingWorkoutSets) return <div>Loading...</div>;
  if (isErrorWorkoutSets) return <div>Error fetching workout sets</div>;

  const sortedWorkoutSets = [...workoutSets].sort(
    (a, b) => a.position - b.position
  );

  return (
    <Box sx={{}}>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          setSelectedSet(null);
          setOpenSetDialog(true);
        }}
        sx={{ mb: 2 }}
      >
        Add New Set
      </Button>
      {sortedWorkoutSets.map((set, index) => (
        <Paper
          key={set.id}
          elevation={0}
          sx={{
            borderRadius: 4,
            overflow: "hidden",
            mb: 2,
            border: (theme) => `1px solid ${theme.palette.border.main}`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: (theme) => theme.palette.background.paperDark,
            }}
          >
            <ListItemText
              primary={`${set.reps}x sets`}
              secondary={
                set.reps > 1 ? `Recovery: ${formatRecoveryTime(set.recovery)}` : null
              }
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={(event) => handleMenuClick(event, set.id)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && selectedSet?.id === set.id}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setOpenSetDialog(true);
                    handleMenuClose();
                  }}
                >
                  <EditIcon /> Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenDeleteDialog(true);
                    handleMenuClose();
                  }}
                >
                  <DeleteIcon /> Delete
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleMoveSet(set.position, "up");
                    handleMenuClose();
                  }}
                  disabled={index === 0}
                >
                  <ArrowUpwardIcon /> Move Up
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMoveSet(set.position, "down");
                    handleMenuClose();
                  }}
                  disabled={index === sortedWorkoutSets.length - 1}
                >
                  <ArrowDownwardIcon /> Move Down
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleClickOpenRep(null);
                    handleMenuClose();
                  }}
                >
                  <AddIcon /> Add Rep
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box>
            <WorkoutRepPod workoutId={workoutId} workoutSetId={set.id} />
          </Box>
        </Paper>
      ))}
      <SetForm
        open={openSetDialog}
        onClose={handleCloseSetDialog}
        onSave={handleSaveSet}
        initialSet={selectedSet}
      />
      <RepForm
        open={openRepDialog}
        onClose={handleCloseRepDialog}
        onSave={handleSaveRep}
        initialData={selectedRep}
      />
      <AreYouSure
        isOpen={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteSet}
        itemName="this set"
      />
    </Box>
  );
};

export default WorkoutSetPod;
