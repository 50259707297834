import React, { createContext, useState, useEffect, useCallback } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userType, setUserType] = useState(localStorage.getItem('userType'));
  const [team, setTeam] = useState(() => {
    const savedTeam = localStorage.getItem('team');
    return savedTeam ? JSON.parse(savedTeam) : null;
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
    if (userType) {
      localStorage.setItem('userType', userType);
    } else {
      localStorage.removeItem('userType');
    }
    if (team) {
      localStorage.setItem('team', JSON.stringify(team));
    } else {
      localStorage.removeItem('team');
    }
  }, [user, token, userType, team]);

  const login = (userData, token, type, teamData) => {
    setUser(userData);
    setToken(token);
    setUserType(type);
    setTeam(teamData);
  };

  const updateUser = (userData) => {
    setUser(prevUser => ({ ...prevUser, ...userData }));
  };

  const updateTeam = (teamData) => {
    setTeam(teamData);
  };

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    setUserType(null);
    setTeam(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('team');
  }, []);

  return (
    <AuthContext.Provider value={{ user, token, userType, team, login, logout, updateTeam, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };