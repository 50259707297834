import Roster from '../../pages/roster/roster';
import TrainingPlan from '../../pages/trainingPlan/trainingPlan';
import React from 'react';
import { Route } from 'react-router-dom';

const routesLoggedIn = () => {
  return (
    <>
      <Route path="/roster/*" element={<Roster />} />
      <Route path="/training-plan/*" element={<TrainingPlan />} />
    </>
  );
};

export default routesLoggedIn;
