import { Box, Typography } from "@mui/material";
import ComponentWrapper from "../../../components/utils/wrappers/componentWrapper";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PeopleIcon from "@mui/icons-material/People";
import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import useApiService from "../../../api/apiService";
import { useNavigate } from "react-router-dom";

const UserCounts = () => {
  const { useWorkouts, useAthletes } = useApiService();
  const {
    data: workouts,
    isLoading: workoutsLoading,
    error: workoutsError,
  } = useWorkouts();
  const {
    data: athletes,
    isLoading: athletesLoading,
    error: athletesError,
  } = useAthletes();
  const navigate = useNavigate();
  const { token, userType, team } = useContext(AuthContext);
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNavigate = (path) => {
    if (token) {
      navigate(path);
    } else {
      navigate("/login");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 3,
        width: "100%",
      }}
    >
      {userType !== "athlete" && (
        <ComponentWrapper
          sx={{
            flex: 1,
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => handleNavigate("/training-plan/workouts")}
        >
          <Typography sx={{ fontSize: ".8rem", fontWeight: "bold" }}>
            {token
              ? workoutsLoading
                ? "Loading..."
                : workoutsError
                ? "Error loading workouts"
                : workouts.length === 0
                ? "Create your first workout!"
                : `Your Workouts: ${workouts.length}`
              : "Automatically calculate workout paces!"}
          </Typography>
          <FitnessCenterIcon />
        </ComponentWrapper>
      )}
      <ComponentWrapper
        sx={{
          flex: 1,
          cursor: "pointer",
          width: "100%",
        }}
        onClick={() => handleNavigate("/roster/athletes")}
      >
        <Typography sx={{ fontSize: ".8rem", fontWeight: "bold" }}>
          {userType !== "athlete"
            ? token
              ? athletesLoading
                ? "Loading..."
                : athletesError
                ? "Error loading athletes"
                : athletes.length === 0
                ? "Create your first athlete!"
                : `Your Athletes: ${athletes.length}`
              : "Log in to manage your athlete roster!"
            : `Your Team: ${team?.name || "Loading..."}`}
        </Typography>
        <PeopleIcon />
      </ComponentWrapper>
    </Box>
  );
};

export { UserCounts };
