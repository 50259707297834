import React, { useState, useContext } from "react";
import { Typography } from "@mui/material";
import useApiService from "../../api/apiService";
import { AuthContext } from "../../context/authContext";
import Form from "../form/form";

const FormPassword = ({ open, handleClose }) => {
  const { user } = useContext(AuthContext);
  const [error, setError] = useState("");

  const { useUpdateUser } = useApiService();
  const updateUserMutation = useUpdateUser();

  const handlePasswordSubmit = async (formData) => {
    if (formData.password !== formData.passwordConfirmation) {
      setError("Passwords do not match");
      return;
    }
    try {
      await updateUserMutation.mutateAsync({ id: user.id, data: formData });
      handleClose();
    } catch (error) {
      console.error("Error updating password:", error);
      setError("Failed to update password. Please try again.");
    }
  };

  const fields = [
    {
      name: "password",
      label: "New Password",
      type: "password",
    },
    {
      name: "passwordConfirmation",
      label: "Confirm New Password",
      type: "password",
    },
  ];

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={fields}
      onSubmit={handlePasswordSubmit}
      title="Update Password"
    >
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Form>
  );
};

export default FormPassword;
