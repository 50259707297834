import { Box, Typography, Chip } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import React, { useEffect } from 'react';
import { 
  CreditCard, 
  People, 
  Poll, 
  Notifications, 
  FileCopy, 
  Message, 
  DirectionsRun, 
  Analytics, 
  Timeline, 
  WbSunny, 
  Speed, 
  EmojiEvents, 
  Star 
} from '@mui/icons-material';

const ComingSoon = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const getIcon = (subFeature) => {
    switch (subFeature) {
      case "Stripe Integration": return <CreditCard fontSize="small" />;
      case "Free Tier": return <People fontSize="small" />;
      case "Pro Tier": return <Star fontSize="small" />;
      case "Forms": return <FileCopy fontSize="small" />;
      case "Polls": return <Poll fontSize="small" />;
      case "Notifications": return <Notifications fontSize="small" />;
      case "Files": return <FileCopy fontSize="small" />;
      case "Messaging": return <Message fontSize="small" />;
      case "Garmin Integration": return <DirectionsRun fontSize="small" />;
      case "Workout Analytics": return <Analytics fontSize="small" />;
      case "Track Workout Performance Over Time": return <Timeline fontSize="small" />;
      case "Weather Integration": return <WbSunny fontSize="small" />;
      case "VDOT":
      case "VVO2":
      case "GOALS": return <Speed fontSize="small" />;
      default: return <EmojiEvents fontSize="small" />;
    }
  };

  return (
    <Box
      ref={ref}
      sx={{
        padding: 3,
        borderRadius: 2,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#0a0a2a', // Dark blue background for night sky
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(5px)',
      }}
    >
      {/* Stars background */}
      {[...Array(50)].map((_, i) => (
        <motion.div
          key={i}
          style={{
            position: 'absolute',
            width: '2px',
            height: '2px',
            backgroundColor: 'white',
            borderRadius: '50%',
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          animate={{
            opacity: [0, 1, 0],
            scale: [0, 1, 0],
          }}
          transition={{
            duration: Math.random() * 3 + 2,
            repeat: Infinity,
            repeatType: 'loop',
            ease: 'easeInOut',
          }}
        />
      ))}
      {/* Shooting star */}
      <motion.div
        style={{
          position: 'absolute',
          width: '3px',
          height: '3px',
          backgroundColor: 'white',
          borderRadius: '50%',
        }}
        animate={{
          top: ['0%', '100%'],
          left: ['100%', '0%'],
          opacity: [1, 0],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: 'loop',
          ease: 'linear',
          repeatDelay: 5,
        }}
      />
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
          Coming Soon!
        </Typography>
      </motion.div>
      {[
        { label: "Create a Team and Add Coaches", strikethrough: true },
        { label: "Free and Pro Subscriptions", strikethrough: true, subFeatures: ["Stripe Integration", "Free Tier", "Pro Tier"] },
        { label: "Team Bulletin Board", pro: true, subFeatures: ["Forms", "Polls", "Notifications", "Files", "Messaging"] },
        { label: "Training Logs", subFeatures: ["Garmin Integration", "Workout Analytics", "Track Workout Performance Over Time", "Weather Integration"], pro: true },
        { label: "More Pace Options", subFeatures: ["VDOT", "VVO2", "GOALS"] },
        { label: "And MUCH More!", pro: true },
      ].map((item, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
          animate={controls}
          variants={{
            visible: { opacity: 1, x: 0 },
          }}
          transition={{ duration: 0.5, delay: 0.2 * (index + 1) }}
          style={{ marginBottom: '8px' }}
        >
          <motion.div
            animate={{
              x: [0, Math.random() * 10 - 5],
              y: [0, Math.random() * 10 - 5],
            }}
            transition={{
              duration: 2 + Math.random() * 2,
              repeat: Infinity,
              repeatType: 'reverse',
              ease: 'easeInOut',
            }}
          >
            <Chip
              label={
                <>
                  {item.strikethrough ? '✅ ' : ''}{item.label}
                  {item.pro && (
                    <Chip
                      label="PRO"
                      size="small"
                      sx={{
                        marginLeft: '4px',
                        backgroundColor: 'gold',
                        color: 'black',
                        fontSize: '0.6rem',
                      }}
                    />
                  )}
                </>
              }
              sx={{
                color: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                textDecoration: item.strikethrough ? 'line-through' : 'none',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              }}
            />
            {item.subFeatures && (
              <Box sx={{ marginLeft: 2, marginTop: 1 }}>
                {item.subFeatures.map((subFeature, subIndex) => (
                  <Chip
                    key={subIndex}
                    icon={getIcon(subFeature)}
                    label={subFeature}
                    size="small"
                    sx={{
                      marginRight: 1,
                      marginBottom: 1,
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      color: 'white',
                      fontSize: '0.7rem',
                    }}
                  />
                ))}
              </Box>
            )}
          </motion.div>
        </motion.div>
      ))}
    </Box>
  );
};

export { ComingSoon };
