import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Button, useTheme, Tabs, Tab, CssBaseline } from '@mui/material';
import { AuthContext } from '../../context/authContext';
import { useNavigate, useLocation } from 'react-router-dom';
import PrivacyPolicy from './privacyPolicy/privacyPolicy';

const PageWrapper = ({ children, title, tabs, rootUrl, sx }) => {
  const { token } = useContext(AuthContext);
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (tabs && tabs.length > 0 && location.pathname === rootUrl) {
      navigate(`${rootUrl}/${tabs[0].path}`);
    }
  }, [location, navigate, tabs, rootUrl]);

  const handleTabChange = (event, newValue) => {
    navigate(`${rootUrl}/${tabs[newValue].path}`);
  };

  const getTabValue = () => {
    return tabs ? tabs.findIndex(tab => location.pathname.includes(tab.path)) : 0;
  };

  const handleContactUs = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const firstName = user?.firstName || '';
    const lastName = user?.lastName || '';
    const id = user?.id || '';
    window.location.href = `mailto:${firstName.toLowerCase()}.${lastName.toLowerCase()}@trackcoachpro.com?subject=Support Request&body=User Name: ${firstName} ${lastName}%0D%0AUser ID: ${id}`;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', marginLeft: token && !isMobile ? '250px' : 0, marginBottom: isMobile ? '64px' : 0, ...sx }}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        {!isMobile && (
          <Typography variant="h4" component="h1" gutterBottom sx={{ p: 3 }}>
            {title}
          </Typography>
        )}
        {tabs && (
          <Box 
            sx={{ 
              borderBottom: 1, 
              borderColor: 'divider', 
              mb: 2, 
              display: 'flex', 
              justifyContent: 'center',
              overflowX: isMobile ? 'auto' : 'visible',
              maxWidth: '100%',
              position: isMobile ? 'sticky' : 'static',
              top: 0,
              zIndex: 10,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Tabs 
              value={getTabValue()} 
              onChange={handleTabChange} 
              aria-label={`${title} tabs`}
              centered={!isMobile}
              variant={isMobile ? 'scrollable' : 'standard'}
              scrollButtons={isMobile ? 'auto' : 'off'}
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} disabled={tab.disabled} />
              ))}
            </Tabs>
          </Box>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: isMobile ? 0 : 3,
            width: '100%',
            marginBottom: isMobile ? '56px' : 0,
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        component="footer"
        sx={{
          py: 2,
          px: isMobile ? 1 : 2,
          mt: 'auto',
          backgroundColor: theme.palette.background.primary,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleContactUs}
            size="small"
          >
            Contact Us
          </Button>
          <PrivacyPolicy open={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)} />
        </Box>
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} Track Tools Pro. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default PageWrapper;