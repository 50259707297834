import React, { useRef } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Tooltip,
} from "@mui/material";
import jsPDF from "jspdf";
import jsPDFAutoTable from "jspdf-autotable";
import Form from "../../../form/form";

const PaceChart = ({ athlete, open, handleClose }) => {
  const chartRef = useRef();

  const convertSecondsToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);
    return minutes > 0
      ? `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
      : `${remainingSeconds}`;
  };

  console.log(athlete);

  // Check if paceChart exists in the athlete object
  if (!athlete) {
    return (
      <Typography>No pace chart data available for this athlete.</Typography>
    );
  }

  const distances = [
    "mile",
    "1600",
    "1000",
    "800",
    "600",
    "400",
    "300",
    "200",
    "100",
  ];

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableData = [];

    Object.keys(athlete.paceChart.training).forEach((paceType) => {
      const paceData = athlete.paceChart.training[paceType];
      const range = paceData.range;
      const rowData = [paceType.replace(/_/g, " ")];

      distances.forEach((distance) => {
        const distanceInMeters =
          distance === "mile" ? 1609 : parseInt(distance);
        const scaledRange = (distanceInMeters / 1609) * range; // Scale range based on distance
        const minPace = paceData.values[distance] - scaledRange;
        const maxPace = paceData.values[distance] + scaledRange;

        rowData.push(
          `${convertSecondsToTime(
            paceData.values[distance]
          )}\n(${convertSecondsToTime(minPace)} - ${convertSecondsToTime(
            maxPace
          )})`
        );
      });

      tableData.push(rowData);
    });

    jsPDFAutoTable(doc, {
      head: [["Pace Type", ...distances]],
      body: tableData,
    });

    doc.save(`${athlete.firstName}_${athlete.lastName}_Pace_Chart.pdf`);
  };

  const renderPaceChart = () => (
    <Box p={1} spacing={1}>
      <Typography variant="h6">
        Pace Chart - {athlete.firstName} {athlete.lastName}
      </Typography>
      <div ref={chartRef}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Pace Type</TableCell>
                {distances.map((distance) => (
                  <TableCell key={distance}>{distance}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(athlete.paceChart.training).map((paceType) => {
                const paceData = athlete.paceChart.training[paceType];
                const range = paceData.range;

                return (
                  <TableRow key={paceType}>
                    <TableCell
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      <Tooltip title={paceData.description}>
                        <span>{paceType.replace(/_/g, " ")}</span>
                      </Tooltip>
                    </TableCell>
                    {distances.map((distance) => {
                      const distanceInMeters =
                        distance === "mile" ? 1609 : parseInt(distance);
                      const scaledRange = (distanceInMeters / 1609) * range; // Scale range based on distance
                      const minPace = paceData.values[distance] - scaledRange;
                      const maxPace = paceData.values[distance] + scaledRange;

                      return (
                        <TableCell key={distance}>
                          {convertSecondsToTime(paceData.values[distance])}
                          <br />
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            noWrap
                            style={{ fontSize: "0.5rem" }}
                          >
                            {convertSecondsToTime(minPace)} -{" "}
                            {convertSecondsToTime(maxPace)}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={downloadPDF}>
          Download as PDF
        </Button>
      </Box>
    </Box>
  );

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={[
        {
          name: "paceChart",
          type: "custom",
          render: () => renderPaceChart(),
        },
      ]}
      onSubmit={() => {}}
      noSubmitButton
      title="Pace Chart"
    />
  );
};

export default PaceChart;
