import React, { useState, useContext } from "react";
import {
  Grid,
  Button,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useApiService from "../../../../api/apiService";
import { TableCoach } from ".";
import { ComponentWrapper } from "../../../../components/utils";
import { AuthContext } from "../../../../context/authContext";
import AreYouSure from "../../../../components/utils/areYouSure/areYouSure";
import Form from "../../../../components/form/form";

function CoachesSection() {
  const [openForm, setOpenForm] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [coachToDelete, setCoachToDelete] = useState(null);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const apiService = useApiService();
  const { userType } = useContext(AuthContext);

  const { data: coaches, refetch: refetchCoaches } = apiService.useCoaches();
  const { mutate: deleteCoach } = apiService.useDeleteCoach();
  const { mutate: createCoach } = apiService.useCreateCoach();
  const { mutate: updateCoach } = apiService.useUpdateCoach();

  const handleCoachCreated = (newCoach) => {
    createCoach(newCoach, {
      onSuccess: () => {
        refetchCoaches();
        handleCloseForm();
      },
    });
  };

  const handleCoachUpdated = (updatedCoach) => {
    const { id, firstName, lastName, email } = updatedCoach;
    updateCoach(
      { id, data: { firstName, lastName, email } },
      {
        onSuccess: () => {
          refetchCoaches();
          handleCloseForm();
        },
      }
    );
  };

  const handleDeleteCoach = async () => {
    deleteCoach(coachToDelete.id, {
      onSuccess: () => {
        refetchCoaches();
        setOpenDeleteDialog(false);
        setCoachToDelete(null);
      },
    });
  };

  const handleOpenForm = async (coach = null) => {
    setSelectedCoach(coach);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedCoach(null);
  };

  const handleMenuClick = (event, coach) => {
    setAnchorEl(event.currentTarget);
    setSelectedCoach(coach);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCoach(null);
  };

  const handleDeleteClick = (coach) => {
    setCoachToDelete(coach);
    setOpenDeleteDialog(true);
    handleMenuClose();
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setCoachToDelete(null);
  };

  const handleOptionsClick = (event) => {
    setOptionsAnchorEl(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setOptionsAnchorEl(null);
  };

  const handleSubmit = (formData) => {
    if (selectedCoach) {
      const updatedCoach = {
        ...selectedCoach,
        ...formData,
      };
      handleCoachUpdated(updatedCoach);
    } else {
      handleCoachCreated(formData);
    }
  };

  const formFields = [
    { name: "firstName", label: "First Name", type: "text", required: true },
    { name: "lastName", label: "Last Name", type: "text", required: true },
    { name: "email", label: "Email", type: "email", required: true },
    { name: "password", label: "Password", type: "password", required: !selectedCoach },
  ];

  return (
    <ComponentWrapper>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box
            p={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {userType === "coach" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenForm()}
                style={{ marginRight: "10px" }}
              >
                Add New Coach
              </Button>
            )}
            {userType === "coach" && (
              <IconButton color="primary" onClick={handleOptionsClick}>
                <MoreVertIcon />
              </IconButton>
            )}
            <Menu
              anchorEl={optionsAnchorEl}
              open={Boolean(optionsAnchorEl)}
              onClose={handleOptionsClose}
            >
              <MenuItem disabled>Import Coaches</MenuItem>
            </Menu>
          </Box>
          <TableCoach
            coaches={coaches || []}
            handleMenuClick={handleMenuClick}
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}
            handleOpenForm={userType === "coach" ? handleOpenForm : null}
            handleDeleteClick={userType === "coach" ? handleDeleteClick : null}
            selectedCoach={selectedCoach}
          />
        </Grid>
      </Grid>
      {userType === "coach" && (
        <Form
          open={openForm}
          onClose={handleCloseForm}
          fields={formFields}
          onSubmit={handleSubmit}
          title={selectedCoach ? "Edit Coach" : "Add New Coach"}
          defaultValues={selectedCoach || {}}
        />
      )}
      <AreYouSure
        isOpen={openDeleteDialog}
        handleClose={handleDeleteDialogClose}
        onConfirm={handleDeleteCoach}
        itemName="this coach"
      />
    </ComponentWrapper>
  );
}

export { CoachesSection };
