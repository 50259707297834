import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';

const RotatingIconButton = styled(IconButton)(({ theme, open }) => ({
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  transform: open ? 'rotate(0deg)' : 'rotate(90deg)',
}));

const MenuIcon = ({ open, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip title="Menu" arrow>
      <RotatingIconButton
        onClick={onClick}
        open={open}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          '&:hover': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
      >
        <MoreVertIcon color={isHovered ? 'primary' : 'inherit'} />
      </RotatingIconButton>
    </Tooltip>
  );
};

export default MenuIcon;
