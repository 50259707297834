import React, { useContext } from "react";
import useApiService from "../../api/apiService";
import { AuthContext } from "../../context/authContext";
import Form from "../form/form";

const FormUser = ({ open, handleClose }) => {
  const { user, updateUser } = useContext(AuthContext);

  const { useUpdateUser } = useApiService();
  const updateUserMutation = useUpdateUser();

  const handleSubmit = async (formData) => {
    try {
      await updateUserMutation.mutateAsync({ id: user.id, data: formData });

      // Update the global state and local storage
      updateUser({
        ...user,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
      });

      // Update local storage
      const storedUser = JSON.parse(localStorage.getItem("user"));
      if (storedUser) {
        const updatedUser = {
          ...storedUser,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
      }

      handleClose();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const fields = [
    {
      name: "firstName",
      label: "First Name",
      type: "text",
    },
    {
      name: "lastName",
      label: "Last Name",
      type: "text",
    },
    {
      name: "email",
      label: "Email",
      type: "email",
    },
  ];

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={fields}
      onSubmit={handleSubmit}
      title="Update User Information"
      defaultValues={{
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
      }}
    />
  );
};

export default FormUser;
