import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreateOrLogin = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ 
      padding: 3, 
      textAlign: 'center', 
      bgcolor: (theme) => `${theme.palette.background.paper}80`, // 50% transparent
      borderRadius: 2, 
      border: '1px solid', 
      borderColor: (theme) => theme.palette.divider 
    }}>
      <Button variant="contained" size="small" color="primary" onClick={() => navigate('/login/create-account')}>
        Create a Team
      </Button>
      <Button variant="contained" size="small" color="secondary" onClick={() => navigate('/login')} sx={{ marginLeft: 2 }}>
        Login
      </Button>
    </Box>
  );
};

export { CreateOrLogin };
