import React from 'react';
import { Paper } from '@mui/material';

const PodWrapper = ({ children }) => {
  return (
    <Paper elevation={0} sx={{ borderRadius: 4, overflow: 'hidden', mb: 2, border: (theme) => `1px solid ${theme.palette.border.main}` }}>
      {children}
    </Paper>
  );
};

export default PodWrapper;
