import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/authContext';

export const RouteSwitch = ({ type, children }) => {
  const { token } = useContext(AuthContext);
  const isAuthenticated = !!token;
  const location = useLocation();

  if (type === 'private' && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (type === 'public' && isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  return <>{children}</>;
};