import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import MobileForm from './mobileForm';
import DesktopForm from './desktopForm';

const Form = ({ open, onClose, fields, onSubmit, title, defaultValues = {}, data = {}, sx = {}, noSubmitButton = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const FormComponent = isMobile ? MobileForm : DesktopForm;

  return (
    <FormComponent
      open={open}
      onClose={onClose}
      fields={fields}
      onSubmit={onSubmit}
      title={title}
      defaultValues={defaultValues}
      data={data}
      sx={sx}
      noSubmitButton={noSubmitButton}
    />
  );
};

export default Form;
