import React from 'react';
import { Chip } from '@mui/material';

const GradeChip = ({ grade, sx }) => {
  const getBackgroundColor = (grade) => {
    switch (grade) {
      case 'freshman':
        return '#FFCDD2';
      case 'sophomore':
        return '#F8BBD0';
      case 'junior':
        return '#E1BEE7';
      case 'senior':
        return '#D1C4E9';
      case '5th_year':
        return '#C5CAE9';
      case '6th_year':
        return '#BBDEFB';
      case '6th':
        return '#B3E5FC';
      case '7th':
        return '#B2EBF2';
      case '8th':
        return '#B2DFDB';
      case '9th':
        return '#C8E6C9';
      case '10th':
        return '#DCEDC8';
      case '11th':
        return '#F0F4C3';
      case '12th':
        return '#FFF9C4';
      case 'alumni':
        return '#FFECB3';
      case 'other':
        return '#FFE0B2';
      default:
        return '#E0E0E0';
    }
  };

  return (
    grade && (
      <Chip 
        label={grade.replace('_', ' ')} 
        size="small" 
        sx={{ 
          backgroundColor: getBackgroundColor(grade),
          ...sx
        }}
      />
    )
  );
};

export default GradeChip;
