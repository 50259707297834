import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useApiService from "../../../../api/apiService";
import ViewModal from "../../../../components/viewModal/viewModal";
import { AthleteWorkoutPod } from "./athleteWorkout/athleteWorkoutPod";
import AthleteWorkoutForm from "./_components/athleteWorkoutForm";
import { lightTheme } from "../../../../theme";
import { AuthContext } from "../../../../context/authContext";
import AreYouSure from "../../../../components/utils/areYouSure/areYouSure";

const DayView = ({ date, dates, onClose }) => {
  const { useAthleteWorkouts, useDeleteAthleteWorkout, queryClient } =
    useApiService();
  const {
    data: athleteWorkouts,
    isLoading,
    isError,
  } = useAthleteWorkouts({ date: date ? date.format("YYYY-MM-DD") : null });
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [expandedWorkoutName, setExpandedWorkoutName] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedWorkoutName, setSelectedWorkoutName] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isOtherCoachWorkoutsExpanded, setIsOtherCoachWorkoutsExpanded] =
    useState(false);
  const useDeleteAthleteWorkoutMutation = useDeleteAthleteWorkout();
  const { userType, user } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const groupWorkouts = (workouts) => {
    return workouts.reduce((acc, workout) => {
      const workoutName = workout.workoutData.name;
      if (!acc[workoutName]) {
        acc[workoutName] = [];
      }
      if (
        !acc[workoutName].some(
          (existingWorkout) => existingWorkout.id === workout.id
        )
      ) {
        acc[workoutName].push(workout);
      }
      return acc;
    }, {});
  };

  const sortedWorkouts = athleteWorkouts
    ? {
        userWorkouts: groupWorkouts(
          athleteWorkouts.filter((workout) => workout.createdBy === user.id)
        ),
        otherCoachWorkouts: groupWorkouts(
          athleteWorkouts.filter((workout) => workout.createdBy !== user.id)
        ),
      }
    : { userWorkouts: {}, otherCoachWorkouts: {} };

  const handleFormOpen = () => {
    setIsFormOpen(true);
    setIsModalOpen(false);
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
    setIsModalOpen(true);
  };

  const handleToggle = (workoutName) => {
    setExpandedWorkoutName(
      expandedWorkoutName === workoutName ? null : workoutName
    );
  };

  const handleMenuOpen = (event, workoutName) => {
    setAnchorEl(event.currentTarget);
    setSelectedWorkoutName(workoutName);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirmationOpen = () => {
    setIsDeleteConfirmationOpen(true);
    handleMenuClose();
  };

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false);
    setSelectedWorkoutName(null);
  };

  const handleDeleteAthleteWorkouts = async () => {
    const athleteWorkoutsToDelete =
      sortedWorkouts.userWorkouts[selectedWorkoutName];
    for (const athleteWorkout of athleteWorkoutsToDelete) {
      await useDeleteAthleteWorkoutMutation.mutateAsync(athleteWorkout.id);
    }
    queryClient.invalidateQueries("calandar");
    handleDeleteConfirmationClose();
  };

  const isBulkMode = Array.isArray(dates);

  const renderWorkouts = (workouts, isOtherCoach = false) =>
    Object.entries(workouts).map(([workoutName, athleteWorkouts]) => (
      <Box
        key={workoutName}
        sx={{
          border: `1px solid ${lightTheme.border.main}`,
          borderRadius: "24px",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            p: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              mr: 2,
              overflow: "hidden",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {userType === "coach" && !isOtherCoach && (
                <IconButton
                  onClick={(event) => handleMenuOpen(event, workoutName)}
                  size="small"
                  sx={{ mr: 1 }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
              <Typography variant="body1" noWrap>
                {workoutName}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                ml: userType === "coach" && !isOtherCoach ? 5 : 0,
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace:
                  expandedWorkoutName === workoutName ? "normal" : "nowrap",
                maxHeight:
                  expandedWorkoutName === workoutName ? "none" : "2.4em",
                transition: "max-height 0.3s ease-out",
                wordWrap: "break-word",
              }}
            >
              {athleteWorkouts[0].description}
            </Typography>
          </Box>
          <IconButton onClick={() => handleToggle(workoutName)}>
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        <Collapse
          in={expandedWorkoutName === workoutName}
          timeout="auto"
          unmountOnExit
        >
          <Box sx={{ p: 2 }}>
            {athleteWorkouts.map((athleteWorkout) => (
              <AthleteWorkoutPod
                key={athleteWorkout.id}
                workout={athleteWorkout}
              />
            ))}
          </Box>
        </Collapse>
      </Box>
    ));

  const renderDayViewContent = () => (
    <Box>
      <Typography sx={{ mt: 2 }}>
        {isBulkMode
          ? `Selected Dates: ${dates.length}`
          : date
          ? date.format("MMMM DD, YYYY")
          : ""}
      </Typography>
      {isLoading ? (
        <CircularProgress sx={{ mt: 2 }} />
      ) : isError ? (
        <Typography sx={{ mt: 2, color: "red" }}>
          Error loading workouts.
        </Typography>
      ) : (
        <Box sx={{ mt: 2, overflow: "auto" }}>
          {Object.keys(sortedWorkouts.userWorkouts).length > 0 ||
          Object.keys(sortedWorkouts.otherCoachWorkouts).length > 0 ? (
            <>
              {renderWorkouts(sortedWorkouts.userWorkouts)}
              {userType === "coach" &&
                Object.keys(sortedWorkouts.otherCoachWorkouts).length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Button
                      onClick={() =>
                        setIsOtherCoachWorkoutsExpanded(
                          !isOtherCoachWorkoutsExpanded
                        )
                      }
                      size="small"
                    >
                      {isOtherCoachWorkoutsExpanded ? "Hide" : "Show"}{" "}
                      Workouts Created by Other Coaches
                    </Button>
                    <Collapse in={isOtherCoachWorkoutsExpanded}>
                      {renderWorkouts(
                        sortedWorkouts.otherCoachWorkouts,
                        true
                      )}
                    </Collapse>
                  </Box>
                )}
              {userType === "athlete" &&
                renderWorkouts(sortedWorkouts.otherCoachWorkouts)}
            </>
          ) : (
            <Typography>No workouts for this date.</Typography>
          )}
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        {userType === "coach" && (
          <Button
            onClick={handleFormOpen}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
          >
            {isBulkMode ? "Add Bulk Workouts" : "Add Workout"}
          </Button>
        )}
        <Button onClick={onClose} sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <ViewModal
        open={isModalOpen && Boolean(date || dates)}
        onClose={onClose}
        sx={{ zIndex: 1301 }}
      >
        {renderDayViewContent()}
      </ViewModal>
      {userType === "coach" && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ zIndex: 13013 }}
        >
          <MenuItem onClick={handleDeleteConfirmationOpen}>Delete</MenuItem>
          {/* <MenuItem onClick={handleMenuClose}>Edit</MenuItem> */}
        </Menu>
      )}
      <AreYouSure
        isOpen={isDeleteConfirmationOpen}
        handleClose={handleDeleteConfirmationClose}
        onConfirm={handleDeleteAthleteWorkouts}
        itemName="all athlete workouts for this workout"
        sx={{ zIndex: 13013 }}
      />
      <AthleteWorkoutForm
        date={date}
        dates={dates}
        isFormOpen={isFormOpen}
        handleClose={handleFormClose}
      />
    </>
  );
};

export default DayView;
