import React from 'react';
import { BrowserRouter, Routes, Route, Outlet, Navigate} from 'react-router-dom';
import App from '../App';
import routesLoggedIn from './routes/routesLoggedIn';
import routesLoggedOut from './routes/routesLoggedOut';
import routesLoggedInOrOut from './routes/routesLoggedInOrOut';
import { RouteSwitch } from './routes/routerSwitch';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          {/* attempot to go home */}
          <Route index element={<Navigate to="/home" />} />

          {/* LOGGED OUT ROUTES */}
          <Route element={<RouteSwitch type="public"><Outlet /></RouteSwitch>}>
            {routesLoggedOut()}
          </Route>

          {/* LOGGED IN ROUTES */}
          <Route element={<RouteSwitch type="private"><Outlet /></RouteSwitch>}>
            {routesLoggedIn()}
          </Route>

          {/* LOGGED IN OR LOGGED OUT ROUTES */}
          {routesLoggedInOrOut()}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
