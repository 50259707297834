import React from 'react';
import { Box, useMediaQuery, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const ModalWrapper = ({ open, handleClose, children, sx }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!open) return null;

  return (
    <Box
      sx={{
        zIndex: 10,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        ...sx,
      }}
      onClick={handleClose}
    >
      <Box
        sx={{
          position: 'relative',
          maxHeight: '90%',
          maxWidth: '90%',
          minWidth: isMobile ? '95%' : '500px',
          bgcolor: 'background.paper',
          border: `2px solid ${theme.palette.border.main}`,
          borderRadius: '20px',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          ...sx,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.background.paper,
            borderRadius: '50%',
            padding: '4px',
          }}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Box>
  );
};

export default ModalWrapper;
