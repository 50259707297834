import Home from '../../pages/home/home';
import React from 'react';
import { Route } from 'react-router-dom';
import PrivacyPolicy from '../../pages/pageWrapper/privacyPolicy/privacyPolicy';

const routesLoggedInOrOut = () => {
  return (
    <>
      <Route path="/home" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy page={true} />} />
    </>
  );
};

export default routesLoggedInOrOut;
