import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from './1.svg';

function MainAppBar() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();

  return (
    <AppBar position={isDesktop ? "fixed" : "static"} sx={{ backgroundColor: (theme) => theme.palette.background.dark }}>
      <Toolbar>
        <Box 
          display="flex" 
          flexDirection="row" 
          alignItems="center" 
          justifyContent="center" 
          width="100%"
        >
          <Box
            onClick={() => navigate('/home')}
            sx={{ cursor: 'pointer' }}
          >
            <img src={Logo} alt="Track Tools Pro" style={{ height: '40px' }} />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export { MainAppBar };