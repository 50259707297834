import React, { useContext } from "react";
import Form from "../form/form";
import useApiService from "../../api/apiService";
import { AuthContext } from "../../context/authContext";

const FormTeam = ({ open, handleClose, onTeamUpdated, team }) => {
  const { useUpdateTeam } = useApiService();
  const updateTeamMutation = useUpdateTeam();
  const { updateTeam } = useContext(AuthContext);

  const handleSubmit = async (submittedData) => {
    try {
      const response = await updateTeamMutation.mutateAsync({
        id: team.id,
        data: submittedData,
      });
      updateTeam(response.data); // Update team in local storage
      onTeamUpdated(response.data);
      handleClose();
    } catch (error) {
      console.error("Error updating team:", error);
    }
  };

  const fields = [
    { name: 'name', label: 'Team Name', type: 'text', required: true },
  ];

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={fields}
      onSubmit={handleSubmit}
      title="Edit Team"
      defaultValues={team || { name: "" }}
    />
  );
};

export default FormTeam;
