import React from "react";
import {
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import jsPDF from "jspdf";
import jsPDFAutoTable from "jspdf-autotable";
import Form from "../../../form/form";

const RacePrediction = ({ athlete, open, handleClose }) => {
  // Check if predictions exist
  if (!athlete || !athlete.paceChart || !athlete.paceChart.prediction) {
    return <Typography>No prediction data available.</Typography>;
  }

  const convertSecondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);
    return hours > 0
      ? `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
          remainingSeconds < 10 ? "0" : ""
        }${remainingSeconds}`
      : `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableData = Object.keys(athlete.paceChart.prediction).map(
      (distance) => [
        distance,
        convertSecondsToTime(athlete.paceChart.prediction[distance]),
      ]
    );

    jsPDFAutoTable(doc, {
      head: [["Distance", "Time"]],
      body: tableData,
    });

    doc.save("Race_Predictions.pdf");
  };

  const renderRacePrediction = () => (
    <Box p={1} spacing={1}>
      <Typography variant="h6">Race Predictions</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Distance</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(athlete.paceChart.prediction).map((distance) => (
              <TableRow key={distance}>
                <TableCell>{distance}</TableCell>
                <TableCell>
                  {convertSecondsToTime(
                    athlete.paceChart.prediction[distance]
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={downloadPDF}>
          Download as PDF
        </Button>
      </Box>
    </Box>
  );

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={[
        {
          name: "racePrediction",
          type: "custom",
          render: () => renderRacePrediction(),
        },
      ]}
      onSubmit={() => {}}
      noSubmitButton
      title="Race Predictions"
    />
  );
};

export default RacePrediction;
