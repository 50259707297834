import { Typography, Box, useMediaQuery, useTheme, Button } from "@mui/material";
import { DirectionsRun, Group } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paperSecondary,
        borderRadius: 2,
        padding: 4,
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" gutterBottom fontWeight="bold" color="primary">
        Elevate Your Coaching Game
      </Typography>
      <Typography variant="h5" gutterBottom color="text.secondary">
        Welcome to Track Tools Pro
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, my: 4, flexWrap: 'wrap' }}>
        <Feature icon={<DirectionsRun />} title="Efficient Training Plans" />
        {/* <Feature icon={<Timeline />} title="Advanced Analytics" /> */}
        <Feature icon={<Group />} title="Team Management" />
      </Box>
      <Typography variant="body1" gutterBottom sx={{ maxWidth: 600, mb: 4 }}>
        {isMobile
          ? "Revolutionize your coaching with our all-in-one solution."
          : "Track Tools Pro is the ultimate coaching companion. Streamline your workflow, optimize athlete performance, and take your team to new heights with our comprehensive suite of tools."}
      </Typography>
      <Button 
        variant="contained" 
        size="large" 
        color="primary" 
        sx={{ fontWeight: 'bold', px: 4, py: 1.5 }}
        onClick={() => navigate('/login/create-account')}
      >
        Create your team today
      </Button>
      <Typography variant="body2" sx={{ mt: 1, cursor: 'pointer' }} onClick={() => navigate('/login')}>
        or login
      </Typography>
    </Box>
  );
};

const Feature = ({ icon, title }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 150 }}>
    <Box sx={{ color: 'primary.main', fontSize: 40, mb: 1 }}>{icon}</Box>
    <Typography variant="subtitle1" fontWeight="bold">{title}</Typography>
  </Box>
);

export { Welcome };
