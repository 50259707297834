import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ComponentWrapper = ({ children, sx, onClick, tooltip }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const content = (
    <Box
      sx={{
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: '20px',
        backgroundColor: theme.palette.background.paper,
        padding: isMobile ? theme.spacing(0) : theme.spacing(2),
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );

  return tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content;
};

export default ComponentWrapper;
