import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Chip,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import useApiService from "../../../../../api/apiService";
import AthleteWorkoutRep from "./athleteWorkoutRep";
import { useTheme } from "@mui/material/styles";
import { SetForm } from "../../workoutsSection/forms/setForm";
import { RepForm } from "../../workoutsSection/forms/repForm";
import { AuthContext } from "../../../../../context/authContext";
import AreYouSure from "../../../../../components/utils/areYouSure/areYouSure";

const AthleteWorkoutSet = ({ workout }) => {
  const {
    useAthleteWorkoutSets,
    useUpdateAthleteWorkoutSet,
    useCreateAthleteWorkoutRep,
    useDeleteAthleteWorkoutSet,
    queryClient,
  } = useApiService();
  const { data: sets } = useAthleteWorkoutSets(workout?.id);
  const theme = useTheme();
  const { userType } = useContext(AuthContext);

  const [openSetDialog, setOpenSetDialog] = useState(false);
  const [openRepDialog, setOpenRepDialog] = useState(false);
  const [selectedSet, setSelectedSet] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const updateAthleteWorkoutSetMutation = useUpdateAthleteWorkoutSet(
    workout?.id,
    selectedSet?.id
  );
  const createAthleteWorkoutRepMutation = useCreateAthleteWorkoutRep(
    workout?.id,
    selectedSet?.id
  );
  const deleteAthleteWorkoutSetMutation = useDeleteAthleteWorkoutSet(
    workout?.id
  );

  if (!workout) {
    return (
      <Typography variant="body2" color="error">
        Workout data is missing.
      </Typography>
    );
  }

  const convertSecondsToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);
    const formattedSeconds = remainingSeconds.endsWith(".00")
      ? Math.floor(remainingSeconds)
      : remainingSeconds;
    return minutes > 0
      ? `${minutes}:${formattedSeconds < 10 ? "0" : ""}${formattedSeconds}`
      : `${formattedSeconds}`;
  };

  const handleClickOpenSet = (set) => {
    if (userType === "coach") {
      setSelectedSet(set);
      setOpenSetDialog(true);
    }
  };

  const handleCloseSetDialog = () => {
    setOpenSetDialog(false);
    setSelectedSet(null);
  };

  const handleSaveSet = async (data) => {
    if (userType !== "coach") return;
    try {
      if (selectedSet) {
        await updateAthleteWorkoutSetMutation.mutateAsync({
          id: selectedSet.id,
          data,
        });
        queryClient.invalidateQueries(["athleteWorkoutSets", workout?.id]);
      }
      handleCloseSetDialog();
    } catch (error) {
      console.error("Error saving set:", error);
    }
  };

  const handleMenuClick = (event, set) => {
    if (userType === "coach") {
      setSelectedSet(set);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenRepDialog = () => {
    if (userType === "coach") {
      setOpenRepDialog(true);
    }
  };

  const handleCloseRepDialog = () => {
    setOpenRepDialog(false);
  };

  const handleSaveRep = async (data) => {
    if (userType !== "coach") return;
    try {
      await createAthleteWorkoutRepMutation.mutateAsync(data);
      handleCloseRepDialog();
    } catch (error) {
      console.error("Error adding rep:", error);
    }
  };

  const handleDeleteSet = async () => {
    if (userType !== "coach") return;
    try {
      await deleteAthleteWorkoutSetMutation.mutateAsync(selectedSet.id);
      queryClient.invalidateQueries(["athleteWorkoutSets", workout?.id]);
      handleMenuClose();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Error deleting set:", error);
    }
  };

  const handleOpenDeleteDialog = () => {
    if (userType === "coach") {
      setOpenDeleteDialog(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Box>
      {sets &&
        sets.map((set, setIndex) => (
          <Box key={set.id || setIndex}>
            <Box
              sx={{
                width: "100%",
                borderBottom: `1px solid ${theme.palette.border.main}`,
                mb: 1,
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <Chip
                  label={`Set ${setIndex + 1}`}
                  variant="contained"
                  size="small"
                  sx={{ width: "90%" }}
                  onClick={() => handleClickOpenSet(set)}
                  color="primary"
                />
                {userType === "coach" && (
                  <IconButton onClick={(event) => handleMenuClick(event, set)}>
                    <MoreVertIcon />
                  </IconButton>
                )}
                {userType === "coach" && (
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedSet?.id === set.id}
                    onClose={handleMenuClose}
                    sx={{ zIndex: 13013 }}
                  >
                    <MenuItem onClick={handleOpenRepDialog}>
                      <AddIcon /> Add Rep
                    </MenuItem>
                    <MenuItem onClick={handleOpenDeleteDialog}>
                      <DeleteIcon /> Delete Set
                    </MenuItem>
                  </Menu>
                )}
              </Box>
              {set.reps > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 1,
                    width: "95%",
                  }}
                >
                  <Chip
                    size="small"
                    label={`Repeat ${set.reps} times`}
                    sx={{ mr: 1, width: "100%" }}
                  />
                  <Chip
                    size="small"
                    label={`${convertSecondsToTime(set.recovery)} rest`}
                    sx={{ ml: 1, width: "100%" }}
                  />
                </Box>
              )}
            </Box>
            <AthleteWorkoutRep workoutId={workout?.id} workoutSetId={set.id} />
          </Box>
        ))}

      {userType === "coach" && (
        <>
          <SetForm
            open={openSetDialog}
            onClose={handleCloseSetDialog}
            onSave={handleSaveSet}
            initialSet={selectedSet}
          />

          <RepForm
            open={openRepDialog}
            onClose={handleCloseRepDialog}
            onSave={handleSaveRep}
          />

          <AreYouSure
            isOpen={openDeleteDialog}
            handleClose={handleCloseDeleteDialog}
            onConfirm={handleDeleteSet}
            itemName="this set"
          />
        </>
      )}
    </Box>
  );
};

export default AthleteWorkoutSet;
