import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import useApiService from "../../../api/apiService";

const Counts = () => {
  const { appCoachCount, appAthleteCount, appAthleteWorkoutsCount } = useApiService();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [coachCount, setCoachCount] = useState("Loading...");
  const [athleteCount, setAthleteCount] = useState("Loading...");
  const [athleteWorkoutsCount, setAthleteWorkoutsCount] = useState("Loading...");

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const coachResponse = await appCoachCount();
        setCoachCount(coachResponse.data.coachCount);

        const athleteResponse = await appAthleteCount();
        setAthleteCount(athleteResponse.data.athleteCount);

        const workoutsResponse = await appAthleteWorkoutsCount();
        setAthleteWorkoutsCount(workoutsResponse.data.athleteWorkoutsCount);
      } catch (error) {
        console.error("Error loading counts:", error);
        setCoachCount("Error");
        setAthleteCount("Error");
        setAthleteWorkoutsCount("Error");
      }
    };

    fetchCounts();
  }, [appCoachCount, appAthleteCount, appAthleteWorkoutsCount]);

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: isMobile ? 2 : 3,
          width: "100%",
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: isMobile ? 2 : 3,
            backgroundColor: (theme) =>
              theme.palette.background.paperSecondary,
            borderRadius: 2,
            textAlign: "center",
            width: isMobile ? "100%" : "auto",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {coachCount}
          </Typography>
          <Typography variant="body1">Active Coaches</Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            padding: isMobile ? 2 : 3,
            backgroundColor: (theme) =>
              theme.palette.background.paperSecondary,
            borderRadius: 2,
            textAlign: "center",
            width: isMobile ? "100%" : "auto",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {athleteCount}
          </Typography>
          <Typography variant="body1">Athletes Registered</Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            padding: isMobile ? 2 : 3,
            backgroundColor: (theme) =>
              theme.palette.background.paperSecondary,
            borderRadius: 2,
            textAlign: "center",
            width: isMobile ? "100%" : "auto",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {athleteWorkoutsCount}
          </Typography>
          <Typography variant="body1">Workouts Scheduled</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export { Counts };
