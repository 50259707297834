import React, { useState, useEffect } from "react";
import {
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  LinearProgress,
  Box,
} from "@mui/material";
import axios from "axios";
import useApiService from "../../../../api/apiService";
import { ModalWrapper } from "../../../../components/utils";

const ImportFromTffrs = ({ open, handleClose }) => {
  const [link, setLink] = useState("");
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [showContinue, setShowContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTeamUrls, setSelectedTeamUrls] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [importedAthletes, setImportedAthletes] = useState([]);
  const [importProgress, setImportProgress] = useState(0);
  const [completed, setCompleted] = useState(false);

  const steps = [
    "Enter TFFRS Link",
    "Select Teams",
    "Import Athletes",
    "Create Athletes",
  ];

  const {
    useAthletes,
    useCreateAthlete,
    useUpdateAthlete,
    useCreateAthletePr,
    findScore,
  } = useApiService();
  const { data: oldAthletes } = useAthletes();
  const existingAthletes = oldAthletes;
  const createAthlete = useCreateAthlete();
  const updateAthlete = useUpdateAthlete();
  const createAthletePr = useCreateAthletePr();

  useEffect(() => {
    setShowContinue(!!link);
  }, [link]);

  const fetchTeamOptions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        link.startsWith("https://www.tfrrs.org/")
          ? link
          : `https://www.tfrrs.org/${link}`
      );
      const parser = new DOMParser();
      const htmlDocument = parser.parseFromString(response.data, "text/html");

      const options = [];
      const urls = [];
      const links = htmlDocument.querySelectorAll(
        ".panel-heading-normal-text a.main-link"
      );
      const teamName = htmlDocument
        .querySelector("#team-name")
        ?.textContent.trim();
      const teamGenderAndSport = htmlDocument
        .querySelector("#team-gender-and-sport")
        ?.textContent.trim();

      if (teamName && teamGenderAndSport) {
        options.push(stripLabel(`${teamName} ${teamGenderAndSport}`));
        urls.push("");
      }

      links.forEach((link) => {
        const text = link.textContent.trim();
        if (text.includes("Cross Country") || text.includes("Track & Field")) {
          options.push(stripLabel(text));
          const href = link.getAttribute("href");
          urls.push(
            href.startsWith("https://www.tfrrs.org/")
              ? href
              : `https://www.tfrrs.org/${href}`
          );
        }
      });

      setTeamOptions(
        options.map((option, index) => ({ name: option, url: urls[index] }))
      );
      setActiveStep(1);
    } catch (error) {
      console.error("Error fetching team options:", error);
    } finally {
      setLoading(false);
    }
  };

  const stripLabel = (label) => {
    const keywords = [
      "men's",
      "men's",
      "women's",
      "mens",
      "womens",
      "track",
      "&",
      "field",
      "cross",
      "country",
    ];
    return label
      .toLowerCase()
      .split(" ")
      .map((word) => word.replace(/\s/g, ""))
      .filter((word) => keywords.includes(word.toLowerCase()))
      .join(" ");
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleTeamChange = (event, newTeams) => {
    setSelectedTeams(newTeams);
    setSelectedTeamUrls(
      newTeams.map(
        (team) => teamOptions.find((option) => option.name === team)?.url || ""
      )
    );
  };

  const handleContinue = async () => {
    if (selectedTeams.length === 0) {
      alert("Please select at least one team before continuing.");
      return;
    }
    setLoading(true);
    setActiveStep(2);
    const newAthleteLinks = new Set();

    for (const teamUrl of selectedTeamUrls) {
      try {
        const url = teamUrl || link;
        const response = await axios.get(
          url.startsWith("https://www.tfrrs.org")
            ? url
            : `https://www.tfrrs.org${url}`
        );
        const parser = new DOMParser();
        const htmlDocument = parser.parseFromString(response.data, "text/html");

        const rosterTable = htmlDocument.querySelector(
          ".col-lg-4 table.tablesaw"
        );
        if (rosterTable) {
          const athleteAnchors = rosterTable.querySelectorAll(
            "tbody tr td:first-child a"
          );
          athleteAnchors.forEach((anchor) => {
            const href = anchor.getAttribute("href");
            newAthleteLinks.add(
              href.startsWith("https://www.tfrrs.org")
                ? href
                : `https://www.tfrrs.org${href}`
            );
          });
        }
      } catch (error) {
        console.error(
          `Error fetching athlete links for team URL ${teamUrl}:`,
          error
        );
      }
    }

    await importAthletes(newAthleteLinks);
    setLoading(false);
  };

  const determineAthleteType = (events) => {
    if (Object.keys(events).length === 0) {
      return ["sprints"];
    }

    const types = new Set();
    const distanceEvents = [
      "1000",
      "800",
      "1500",
      "3000",
      "5000",
      "10,000",
      "MILE",
      "8K (XC)",
      "6K (XC)",
      "5K (XC)",
      "4.5 MILE (XC)",
      "3000S",
      "7K (XC)",
      "10K (XC)",
      "4K (XC)",
      "4 MILE (XC)",
      "4x800",
      "DMR",
    ];
    const sprintEvents = [
      "60",
      "100",
      "200",
      "300",
      "400",
      "600",
      "4x100",
      "4x200",
      "4x400",
    ];
    const hurdleEvents = ["60H", "100H", "110H", "300H", "400H"];
    const jumpEvents = ["HJ", "LJ", "TJ", "PV"];
    const throwEvents = ["SP", "DT", "HT", "JT"];

    for (const event in events) {
      if (distanceEvents.some((e) => event.includes(e))) types.add("distance");
      if (sprintEvents.some((e) => event.includes(e))) types.add("sprints");
      if (hurdleEvents.some((e) => event.includes(e))) types.add("sprints");
      if (jumpEvents.some((e) => event.includes(e))) types.add("jumps");
      if (throwEvents.some((e) => event.includes(e))) types.add("throws");
      if (event.includes("PV")) types.add("pole_vault");
    }

    return Array.from(types);
  };

  const convertGrade = (grade) => {
    const gradeMap = {
      FR: "freshman",
      SO: "sophomore",
      JR: "junior",
      SR: "senior",
    };
    const baseGrade = grade.slice(0, 2).toUpperCase();
    return gradeMap[baseGrade] || "other";
  };

  const convertTimeToSeconds = (timeString) => {
    const parts = timeString.split(":").map(Number);
    let seconds = 0;
    if (parts.length === 3) {
      seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
      seconds = parts[0] * 60 + parts[1];
    } else if (parts.length === 1) {
      seconds = parts[0];
    }
    return seconds;
  };

  const calculateFScore = async (prs) => {
    let highestFScore = 200;
    const distanceEvents = {
      800: 800,
      1000: 1000,
      1500: 1500,
      MILE: 1609,
      3000: 3000,
      "3000S": 3000,
      5000: 5000,
      "10,000": 10000,
      "8K (XC)": 8000,
      "6K (XC)": 6000,
      "5K (XC)": 5000,
      "4.5 MILE (XC)": 7242,
      "7K (XC)": 7000,
      "10K (XC)": 10000,
      "4K (XC)": 4000,
      "4 MILE (XC)": 6437,
    };

    for (const [event, performance] of Object.entries(prs)) {
      if (distanceEvents[event]) {
        const timeInSeconds = convertTimeToSeconds(performance);
        try {
          const response = await findScore({
            timeInSeconds: timeInSeconds,
            race: distanceEvents[event].toString(),
          });
          if (response.data && response.data.fScore) {
            const fScore = Number(response.data.fScore);
            if (fScore > highestFScore) {
              highestFScore = fScore;
            }
          }
        } catch (error) {
          console.error(`Error calculating FScore for ${event}:`, error);
        }
      }
    }

    return highestFScore.toFixed(2);
  };

  const importAthletes = async (athleteLinks) => {
    const importedAthletesData = [];
    const totalAthletes = athleteLinks.size;
    let importedCount = 0;

    for (const link of athleteLinks) {
      try {
        const response = await axios.get(link);
        const parser = new DOMParser();
        const htmlDocument = parser.parseFromString(response.data, "text/html");

        const nameElement = htmlDocument.querySelector(
          ".panel-title.large-title"
        );
        let name = nameElement?.textContent.trim() || "";
        const rawGrade = name.match(/\((.*?)\)/)?.[1] || "FR";
        const grade = convertGrade(rawGrade);
        name = name.replace(/\(.*?\)/g, "").trim(); // Remove grade and anything else in parentheses
        const [firstName, ...lastNameParts] = name.split(" ");
        const lastName = lastNameParts.join(" ");
        const prs = {};

        const tables = htmlDocument.querySelectorAll(
          ".table.bests, .table.xc_bests, .table.indoor_bests, .table.outdoor_bests"
        );
        tables.forEach((table) => {
          const rows = table.querySelectorAll("tr");
          rows.forEach((row) => {
            const cells = row.querySelectorAll("td");
            for (let i = 0; i < cells.length; i += 2) {
              const event = cells[i]?.textContent.trim();
              const performance = cells[i + 1]
                ?.querySelector("a")
                ?.textContent.trim();
              if (event && performance && event !== "\xa0") {
                prs[event] = performance;
              }
            }
          });
        });

        const athleteTypes = determineAthleteType(prs);
        const gender = link.includes("/female/") ? "female" : "male";
        const fScore = athleteTypes.includes("distance")
          ? await calculateFScore(prs)
          : 0;

        importedAthletesData.push({
          firstName:
            firstName.charAt(0).toUpperCase() +
            firstName.slice(1).toLowerCase(),
          lastName:
            lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase(),
          grade,
          prs,
          tffrsUrl: link,
          athleteTypes,
          gender,
          fScore,
        });

        importedCount++;
        setImportProgress((importedCount / totalAthletes) * 100);
      } catch (error) {
        console.error(`Error importing athlete data from ${link}:`, error);
      }
    }
    setImportedAthletes(importedAthletesData);
    setActiveStep(3);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const createAthletes = async () => {
    setLoading(true);
    let createdCount = 0;
    const totalAthletes = importedAthletes.length;

    for (const athlete of importedAthletes) {
      const existingAthlete = existingAthletes?.find(
        (a) =>
          a?.firstName?.toLowerCase().trim() ===
            athlete.firstName.toLowerCase().trim() &&
          a?.lastName?.toLowerCase().trim() ===
            athlete.lastName.toLowerCase().trim()
      );

      try {
        let athleteId;
        if (existingAthlete) {
          await updateAthlete.mutateAsync({
            id: existingAthlete.id,
            data: { tffrsLink: athlete.tffrsUrl },
          });
          athleteId = existingAthlete.id;
        } else {
          const newAthlete = await createAthlete.mutateAsync({
            firstName: athlete.firstName,
            lastName: athlete.lastName,
            grade: athlete.grade,
            gender: athlete.gender,
            tffrsLink: athlete.tffrsUrl,
            athleteTypes: athlete.athleteTypes,
            fScore: athlete.fScore,
          });
          athleteId = newAthlete.data.id;
        }

        for (const [event, performance] of Object.entries(athlete.prs)) {
          const isDistanceEvent = [
            "SP",
            "DT",
            "HT",
            "JT",
            "LJ",
            "TJ",
            "HJ",
            "PV",
          ].some((e) => event.includes(e));
          let timeInSeconds = null;
          let distance = null;
          if (isDistanceEvent) {
            if (performance.includes(".")) {
              distance = parseFloat(performance);
            } else {
              const [feet, inches] = performance.split("-").map(Number);
              distance = Math.round((feet * 12 + inches) * 0.0254);
            }
          } else {
            timeInSeconds = convertTimeToSeconds(performance);
          }
          await createAthletePr.mutateAsync({
            athleteId: athleteId,
            event: event,
            unitType: isDistanceEvent ? "distance" : "time",
            distance: isDistanceEvent ? distance : null,
            time: isDistanceEvent ? null : timeInSeconds,
            source: "tffrs",
          });
        }

        createdCount++;
        setImportProgress((createdCount / totalAthletes) * 100);
      } catch (error) {
        console.error(
          `Error creating/updating athlete ${athlete.firstName} ${athlete.lastName}:`,
          error
        );
      }

      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    setLoading(false);
    setCompleted(true);
  };

  const handleCloseAndReset = () => {
    setLink("");
    setTeamOptions([]);
    setSelectedTeams([]);
    setShowContinue(false);
    setLoading(false);
    setSelectedTeamUrls([]);
    setActiveStep(0);
    setImportedAthletes([]);
    setImportProgress(0);
    setCompleted(false);
    handleClose();
  };

  return (
    <ModalWrapper open={open} handleClose={handleCloseAndReset}>
      <>
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <Stepper activeStep={activeStep} sx={{ marginBottom: 3 }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{index === activeStep ? label : null}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {activeStep === 0 && (
          <>
            <TextField
              label="TFFRS Link"
              variant="outlined"
              value={link}
              onChange={handleLinkChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <Button
              variant="contained"
              onClick={fetchTeamOptions}
              disabled={!showContinue || loading}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : "Continue"}
            </Button>
          </>
        )}
        {activeStep === 1 && teamOptions.length > 0 && (
          <>
            <Typography variant="h6" gutterBottom>
              Select Teams:
            </Typography>
            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "auto",
                width: "100%",
                marginBottom: 2,
              }}
            >
              <ToggleButtonGroup
                value={selectedTeams}
                onChange={handleTeamChange}
                orientation="vertical"
                sx={{ width: "100%" }}
              >
                {teamOptions.map((option, index) => (
                  <ToggleButton
                    key={index}
                    value={option.name}
                    sx={{ justifyContent: "flex-start", textAlign: "left" }}
                  >
                    {option.name}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            <Button
              variant="contained"
              onClick={handleContinue}
              disabled={loading}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : "Continue"}
            </Button>
          </>
        )}
        {activeStep === 2 && (
          <>
            <Typography variant="h6" gutterBottom>
              Importing Athletes:
            </Typography>
            <LinearProgress
              variant="determinate"
              value={importProgress}
              sx={{ marginBottom: 2 }}
            />
            <Typography>{Math.round(importProgress)}% Complete</Typography>
          </>
        )}
        {activeStep === 3 && (
          <>
            <Typography variant="h6" gutterBottom>
              Creating Athletes:
            </Typography>
            {loading ? (
              <>
                <LinearProgress
                  variant="determinate"
                  value={importProgress}
                  sx={{ marginBottom: 2 }}
                />
                <Typography>{Math.round(importProgress)}% Complete</Typography>
              </>
            ) : completed ? (
              <Box sx={{ textAlign: "center", py: 2 }}>
                <Typography variant="h5" color="primary" gutterBottom>
                  Import Completed!
                </Typography>
                <Typography>
                  All athletes have been successfully imported and created.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleCloseAndReset}
                  sx={{ mt: 2 }}
                >
                  Close
                </Button>
              </Box>
            ) : (
              <Button variant="contained" onClick={createAthletes} fullWidth>
                Start Creating Athletes
              </Button>
            )}
          </>
        )}
        {activeStep > 0 && !completed && (
          <Button onClick={handleBack} sx={{ marginTop: 2 }}>
            Back
          </Button>
        )}
      </>
    </ModalWrapper>
  );
};

export default ImportFromTffrs;
