import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  ListItem,
  Checkbox,
  Menu,
  MenuItem,
  Divider,
  Collapse,
  Chip,
  Button,
} from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SpeedIcon from "@mui/icons-material/Speed";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SchoolIcon from "@mui/icons-material/School";
import EmailIcon from "@mui/icons-material/Email";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LinkIcon from "@mui/icons-material/Link";
import FormAthlete from "./_components/formAthlete";
import PaceChart from "./_components/paceChart";
import RacePrediction from "./_components/racePrediction";
import AthletesPrs from "./_components/athletesPrs";
import CollapseIcon from "../../utils/collapseIcon/collapseIcons";
import MenuIcon from "../../utils/menuIcon";
import GradeChip from "./_components/gradeChip";
import { AuthContext } from "../../../context/authContext";
import useApiService from "../../../api/apiService";
import ModalWrapper from "../../utils/wrappers/modalWrapper";
import Form from "../../form/form";

const AthletePod = ({
  athlete,
  showFields = ["name", "gender"],
  selectable = false,
  showInvite = true,
  selected = false,
  onSelectChange,
  options = false,
  handleDeleteClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openPaceChartDialog, setOpenPaceChartDialog] = useState(false);
  const [openRacePredictionDialog, setOpenRacePredictionDialog] =
    useState(false);
  const [openPrsDialog, setOpenPrsDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [inviteEmail, setInviteEmail] = useState(athlete?.email || "");
  const { userType } = useContext(AuthContext);
  const { useUpdateAthlete } = useApiService();
  const updateAthlete = useUpdateAthlete();

  const extraFields = ["email", "fScore", "tffrsLink"];
  const hasExtraFields = extraFields.some((field) =>
    showFields.includes(field)
  );

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpenFormDialog(true);
    setAnchorEl(null);
  };

  const handlePaceChartClick = () => {
    setOpenPaceChartDialog(true);
    setAnchorEl(null);
  };

  const handleRacePredictionClick = () => {
    setOpenRacePredictionDialog(true);
    setAnchorEl(null);
  };

  const handlePrsClick = () => {
    setOpenPrsDialog(true);
    setAnchorEl(null);
  };

  const handleExpandClick = (event) => {
    if (hasExtraFields) {
      event.stopPropagation();
      setExpanded(!expanded);
    }
  };

  const handlePodClick = () => {
    if (selectable) {
      onSelectChange(!selected);
    } else if (hasExtraFields) {
      setExpanded(!expanded);
    }
  };

  const handleInviteClick = () => {
    setOpenInviteDialog(true);
    setAnchorEl(null);
  };

  const handleInviteSubmit = (formData) => {
    updateAthlete.mutate(
      {
        id: athlete.id,
        data: { email: formData.email },
      },
      {
        onSuccess: () => {
          setOpenInviteDialog(false);
          setInviteEmail("");
        },
      }
    );
  };

  const handleTffrsLinkClick = () => {
    if (athlete?.tffrsLink) {
      window.open(athlete.tffrsLink, "_blank");
    }
  };

  return (
    <ListItem key={athlete?.id || "unknown"} sx={{ p: 0.5 }}>
      <Box
        onClick={handlePodClick}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          pl: 1,
          pr: 1,
          border: (theme) => `1px solid ${theme.palette.border.main}`,
          borderRadius: "20px",
          cursor: selectable || hasExtraFields ? "pointer" : "default",
          backgroundColor: (theme) => theme.palette.background.paperSecondary,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            {selectable && (
              <Checkbox
                checked={selected}
                onChange={(e) => {
                  e.stopPropagation();
                  onSelectChange(!selected);
                }}
                onClick={(e) => e.stopPropagation()}
                sx={{ mr: 0 }}
              />
            )}
            {options && (
              <MenuIcon open={Boolean(anchorEl)} onClick={handleMenuClick} />
            )}
            {showFields.includes("name") && (
              <Typography
                sx={{ ml: 1, display: { xs: "block", sm: "block" } }}
              >{`${athlete?.firstName || "Unknown"} ${
                athlete?.lastName || "Athlete"
              }`}</Typography>
            )}
            {showFields.includes("grade") && athlete?.grade && (
              <GradeChip
                grade={athlete?.grade}
                sx={{ ml: 2, display: { xs: "none", sm: "block" } }}
              />
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {userType === "coach" && !athlete.invited && showInvite && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleInviteClick}
                sx={{ mr: 1 }}
              >
                Invite
              </Button>
            )}
            {(athlete.invited || !showInvite) &&
              showFields.includes("gender") &&
              (athlete?.gender === "male" ? (
                <MaleIcon
                  sx={{ color: (theme) => theme.palette.gender.male, mr: 1 }}
                />
              ) : (
                <FemaleIcon
                  sx={{ color: (theme) => theme.palette.gender.female, mr: 1 }}
                />
              ))}
            {(athlete.invited || !showInvite) && hasExtraFields && (
              <CollapseIcon expanded={expanded} onClick={handleExpandClick} />
            )}
          </Box>
        </Box>

        {hasExtraFields && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box
              sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1, p: 1 }}
            >
              {showFields.includes("grade") && athlete?.grade && (
                <GradeChip
                  grade={athlete?.grade}
                  sx={{ display: { xs: "block", sm: "none" } }}
                />
              )}
              {showFields.includes("email") && athlete?.email && (
                <Chip
                  icon={<EmailIcon />}
                  label={athlete?.email}
                  variant="outlined"
                />
              )}
              {showFields.includes("athleteTypes") &&
                athlete?.athleteTypes &&
                athlete?.athleteTypes.map((type) => (
                  <Chip key={type} label={type} variant="contained" />
                ))}
              {showFields.includes("tffrsLink") && athlete?.tffrsLink && (
                <Chip
                  icon={<LinkIcon />}
                  label="TFFRS"
                  variant="outlined"
                  onClick={handleTffrsLinkClick}
                  clickable
                />
              )}
              {/* Add any other fields here as Chips */}
            </Box>
          </Collapse>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {userType === "coach" && (
          <>
            <MenuItem onClick={handleEditClick}>
              <EditIcon fontSize="small" style={{ marginRight: "8px" }} />
              Edit
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {
              handleDeleteClick(athlete);
              handleMenuClose();
            }}>
              <DeleteIcon fontSize="small" style={{ marginRight: "8px" }} />
              Remove from Team
            </MenuItem>
            <MenuItem disabled>
              <SchoolIcon
                fontSize="small"
                style={{ marginRight: "8px", opacity: 0.5 }}
              />
              Graduate Athlete
            </MenuItem>
            <Divider />
          </>
        )}
        {athlete?.fScore > 0 && (
          <>
            <MenuItem onClick={handlePaceChartClick}>
              <SpeedIcon fontSize="small" style={{ marginRight: "8px" }} />
              View Pace Chart
            </MenuItem>
            <MenuItem onClick={handleRacePredictionClick}>
              <AssessmentIcon fontSize="small" style={{ marginRight: "8px" }} />
              View Race Prediction
            </MenuItem>
          </>
        )}
        <MenuItem onClick={handlePrsClick}>
          <EmojiEventsIcon fontSize="small" style={{ marginRight: "8px" }} />
          PRs
        </MenuItem>
        {athlete?.tffrsLink && (
          <MenuItem onClick={handleTffrsLinkClick}>
            <LinkIcon fontSize="small" style={{ marginRight: "8px" }} />
            TFFRS Profile
          </MenuItem>
        )}
      </Menu>

      <FormAthlete
        open={openFormDialog}
        handleClose={() => setOpenFormDialog(false)}
        athlete={athlete}
        onAthleteUpdated={() => setOpenFormDialog(false)}
      />

      {openPaceChartDialog && athlete?.fScore > 0 && (
        <PaceChart
          athlete={athlete}
          open={openPaceChartDialog}
          handleClose={() => setOpenPaceChartDialog(false)}
          sx={{ width: "90%" }}
        />
      )}

      {openRacePredictionDialog && athlete?.fScore > 0 && (
        <RacePrediction
          athlete={athlete}
          open={openRacePredictionDialog}
          handleClose={() => setOpenRacePredictionDialog(false)}
          sx={{ width: "90%" }}
        />
      )}

      {openPrsDialog && (
        <ModalWrapper
          open={openPrsDialog}
          handleClose={() => setOpenPrsDialog(false)}
        >
          <AthletesPrs athlete={athlete} />
        </ModalWrapper>
      )}

      <Form
        open={openInviteDialog}
        onClose={() => setOpenInviteDialog(false)}
        fields={[
          {
            name: "email",
            label: "Email",
            type: "email",
          },
        ]}
        onSubmit={handleInviteSubmit}
        title="Invite Athlete"
        defaultValues={{ email: inviteEmail }}
      />
    </ListItem>
  );
};

export { AthletePod };
