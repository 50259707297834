import { toast } from 'sonner';

const handleApiError = (error) => {
  const { status } = error.response;
  const message = error.response.data.message || error.message;

  if (status < 200 || status >= 300) {
    switch (status) {
      case 400:
        toast.error(message || 'Action could not be completed');
        break;
      case 402:
        toast.error(message || 'Subscription upgrade required');
        break;
      case 422:
        toast.error(message || 'Invalid form data');
        break;
      default:
        toast.error(message || 'An unexpected error occurred');
        break;
    }
  }
};

export default handleApiError;
