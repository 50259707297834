import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  Chip,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import useApiService from "../../../api/apiService";
import ModalWrapper from "../../../components/utils/wrappers/modalWrapper";
import { ComponentWrapper } from "../../utils";

const SelectEventGroup = ({
  open,
  onClose,
  eventGroups,
  selectedEventGroupIds,
  onSave,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroups, setSelectedGroups] = useState(
    selectedEventGroupIds || []
  );

  const filteredEventGroups = eventGroups.filter((group) =>
    group.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const { queryClient } = useApiService();

  function handleSave() {
    onSave(selectedGroups);
    setTimeout(() => {
      queryClient.invalidateQueries(["eventGroups"]);
    }, 1500);
  }

  const handleGroupToggle = (groupId) => {
    setSelectedGroups((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId]
    );
  };

  return (
    <ModalWrapper open={open} handleClose={onClose} sx={{ maxWidth: "xs" }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Select Event Groups
      </Typography>
      <TextField
        autoFocus
        size="small"
        label="Search Event Groups"
        type="search"
        fullWidth
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 1 }}
      />
      <Box sx={{ maxHeight: 300, overflow: "auto" }}>
        <FormControl component="fieldset" fullWidth>
          <FormGroup sx={{ mt: 1 }}>
            {filteredEventGroups.map((group) => (
              <ComponentWrapper key={group.id} sx={{ mb: 0.5, p: 0 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedGroups.includes(group.id)}
                        onChange={() => handleGroupToggle(group.id)}
                        size="small"
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {group.name}
                      </Typography>
                    }
                    sx={{ m: 0, flexGrow: 1 }}
                  />
                  <Chip
                    icon={<PeopleIcon />}
                    label={group.athletes.length.toString().padStart(2, "0")}
                    size="small"
                    variant="outlined"
                    sx={{ minWidth: 32, justifyContent: "center", mr: 1 }}
                  />
                </Box>
              </ComponentWrapper>
            ))}
          </FormGroup>
        </FormControl>
      </Box>
      {filteredEventGroups.length === 0 && (
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ py: 1 }}
        >
          No event groups found
        </Typography>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
        <Button onClick={onClose} color="primary" size="small" sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          size="small"
        >
          Save
        </Button>
      </Box>
    </ModalWrapper>
  );
};

export { SelectEventGroup };
