import LogInSignUpPage from '../../pages/logInSignUp/page';
import React from 'react';
import { Route } from 'react-router-dom';
import InvitedPage from '../../pages/invited/page';

const routesLoggedOut = () => {
  return (
    <>
      <Route path="/login/*" element={<LogInSignUpPage />} />
      <Route path="/invited/*" element={<InvitedPage />} />
    </>
  );
};

export default routesLoggedOut;
