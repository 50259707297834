import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import MobileModal from './mobileModal';
import DesktopModal from './desktopModal';

const ViewModal = ({ open, onClose, children, sx = {} }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const ModalComponent = isMobile ? MobileModal : DesktopModal;

  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      sx={sx}
    >
      {children}
    </ModalComponent>
  );
};

export default ViewModal;
