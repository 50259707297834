import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import useApiService from "../../../../api/apiService";
import { ComponentWrapper } from "../../../utils";
import Form from "../../../form/form";

const eventOptions = [
  "60", "100", "200", "300", "400", "60H", "100H", "110H", "300H", "400H",
  "600", "800", "1000", "1500", "MILE", "1600", "3000", "3000S", "3200",
  "5000", "10,000", "4K (XC)", "5K (XC)", "6K (XC)", "7K (XC)", "8K (XC)",
  "10K (XC)", "4 MILE (XC)", "HJ", "PV", "LJ", "TJ", "SP", "HT", "JT",
  "PENT", "HEP", "DEC",
];

const AthletesPrs = ({ athlete }) => {
  const [prs, setPrs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPr, setSelectedPr] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const {
    useAthletesPrs,
    useCreateAthletePr,
    useUpdateAthletePr,
    useDeleteAthletePr,
  } = useApiService();
  const { data: athletePrs, refetch: refetchPrs } = useAthletesPrs(athlete.id);
  const createPr = useCreateAthletePr();
  const updatePr = useUpdateAthletePr();
  const deletePr = useDeleteAthletePr();

  useEffect(() => {
    if (athletePrs) {
      setPrs(athletePrs);
    }
  }, [athletePrs]);

  const handleMenuClick = (event, pr) => {
    setAnchorEl(event.currentTarget);
    setSelectedPr(pr);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (pr = null) => {
    setSelectedPr(pr);
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPr(null);
  };

  const handleSubmit = (formData) => {
    const prData = {
      athleteId: athlete.id,
      event: formData.event,
      unitType: formData.unitType,
      date: formData.date,
      source: formData.source,
    };

    if (formData.unitType === "time") {
      prData.time = convertTimeToSeconds(formData.time);
    } else {
      if (formData.distanceUnit === "meters") {
        prData.distance = parseInt(formData.distance);
      } else {
        const feet = parseInt(formData.feet) || 0;
        const inches = parseInt(formData.inches) || 0;
        prData.distance = Math.round((feet * 12 + inches) * 0.0254);
      }
    }

    if (selectedPr) {
      updatePr.mutate(
        { id: selectedPr.id, data: prData, athleteId: athlete.id },
        {
          onSuccess: () => {
            refetchPrs();
            handleCloseDialog();
          },
        }
      );
    } else {
      createPr.mutate(
        { ...prData, athleteId: athlete.id },
        {
          onSuccess: () => {
            refetchPrs();
            handleCloseDialog();
          },
        }
      );
    }
  };

  const handleDelete = () => {
    if (selectedPr) {
      deletePr.mutate(
        { athleteId: athlete.id, id: selectedPr.id },
        {
          onSuccess: () => {
            refetchPrs();
            handleMenuClose();
          },
        }
      );
    }
  };

  const formatDisplayValue = (pr) => {
    if (pr.unitType === "time") {
      return formatSecondsToTime(pr.time);
    } else if (pr.unitType === "distance" && pr.distance !== null) {
      const meters = pr.distance.toFixed(2);
      const feet = Math.floor(pr.distance * 3.28084);
      const inches = Math.round((pr.distance * 39.3701) % 12);
      return `${meters} meters (${feet}' ${inches}")`;
    } else {
      return "N/A";
    }
  };

  const formatSecondsToTime = (seconds) => {
    if (seconds === null || isNaN(seconds)) return "N/A";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);

    if (hours === 0 && minutes === 0) {
      return `${remainingSeconds}`;
    } else if (hours === 0) {
      return `${minutes}:${remainingSeconds.padStart(5, "0")}`;
    } else {
      return `${hours}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.padStart(5, "0")}`;
    }
  };

  const convertTimeToSeconds = (timeString) => {
    if (!timeString) return null;
    const parts = timeString.split(":").map(Number);
    let seconds = 0;
    if (parts.length === 3) {
      seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
      seconds = parts[0] * 60 + parts[1];
    } else if (parts.length === 1) {
      seconds = parts[0];
    }
    return isNaN(seconds) ? null : seconds;
  };

  const formFields = [
    {
      name: "event",
      label: "Event",
      type: "singleSelect",
      options: eventOptions.map(event => ({ value: event, label: event })),
    },
    {
      name: "unitType",
      label: "Unit Type",
      type: "singleSelect",
      options: [
        { value: "time", label: "Time" },
        { value: "distance", label: "Distance" },
      ],
    },
    {
      name: "time",
      label: "Time (HH:MM:SS.ss)",
      type: "text",
      placeholder: "00:00:00.00",
      conditional: (formData) => formData.unitType === "time",
    },
    {
      name: "distanceUnit",
      label: "Distance Unit",
      type: "singleSelect",
      options: [
        { value: "meters", label: "Meters" },
        { value: "feet", label: "Feet and Inches" },
      ],
      conditional: (formData) => formData.unitType === "distance",
    },
    {
      name: "distance",
      label: "Distance (meters)",
      type: "number",
      conditional: (formData) => formData.unitType === "distance" && formData.distanceUnit === "meters",
    },
    {
      name: "feet",
      label: "Feet",
      type: "number",
      conditional: (formData) => formData.unitType === "distance" && formData.distanceUnit === "feet",
    },
    {
      name: "inches",
      label: "Inches",
      type: "number",
      conditional: (formData) => formData.unitType === "distance" && formData.distanceUnit === "feet",
    },
    {
      name: "date",
      label: "Date",
      type: "date",
    },
  ];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Personal Records
        <IconButton
          onClick={() => handleOpenDialog()}
          size="small"
          sx={{ ml: 1 }}
        >
          <AddIcon />
        </IconButton>
      </Typography>{" "}
      {prs.map((pr) => (
        <ComponentWrapper key={pr.id} sx={{ mb: 1, p: 0 }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton onClick={(e) => handleMenuClick(e, pr)}>
              <MoreVertIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mr: 2,
              }}
            >
              <Typography>{pr.event}</Typography>
              <Typography>{formatDisplayValue(pr)}</Typography>
            </Box>
          </Box>
        </ComponentWrapper>
      ))}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleOpenDialog(selectedPr)}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
      <Form
        open={openDialog}
        onClose={handleCloseDialog}
        fields={formFields}
        onSubmit={handleSubmit}
        title={selectedPr ? "Edit PR" : "Add New PR"}
        defaultValues={selectedPr ? {
          event: selectedPr.event,
          unitType: selectedPr.unitType,
          time: selectedPr.unitType === "time" ? formatSecondsToTime(selectedPr.time) : "",
          distance: selectedPr.unitType === "distance" ? selectedPr.distance : "",
          distanceUnit: "meters",
          date: selectedPr.date,
          source: selectedPr.source,
        } : {
          event: "",
          unitType: "time",
          time: "",
          distance: "",
          distanceUnit: "meters",
          feet: "",
          inches: "",
          date: "",
          source: "coach",
        }}
      />
    </Box>
  );
};

export default AthletesPrs;
