import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PeopleIcon from "@mui/icons-material/People";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AthletePod } from "../../../../../components/pods";
import MenuIcon from "../../../../../components/utils/menuIcon/menuIcon";
import PodWrapper from "../../../../../components/utils/wrappers/podWrapper";
import Form from "../../../../../components/form/form";

const EventGroupPod = ({
  group,
  onManageAthletes,
  onDeleteGroup,
  onEditGroup,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleManageClick = (e) => {
    e.stopPropagation();
    onManageAthletes(group);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setOpenEditDialog(true);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteGroup(group.id);
  };

  const handleEditSubmit = (formData) => {
    onEditGroup(group.id, formData.name);
    setOpenEditDialog(false);
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const athletesInGroup = group.athletes || [];

  const formFields = [
    {
      name: "name",
      label: "Group Name",
      type: "text",
    },
  ];

  return (
    <PodWrapper>
      <Box
        onClick={handleChange}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box>
            <IconButton onClick={handleMenuClick} size="small">
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleManageClick}>
                <PeopleIcon sx={{ mr: 1 }} />
                Manage Athletes
              </MenuItem>
              <MenuItem onClick={handleEditClick}>
                <EditIcon sx={{ mr: 1 }} />
                Edit
              </MenuItem>
              <MenuItem onClick={handleDeleteClick}>
                <DeleteIcon sx={{ mr: 1 }} />
                Delete
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography noWrap variant="b2">
              {group.name.length > 10
                ? `${group.name.substring(0, 7)}...`
                : group.name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }} mr={2}>
          <Chip
            icon={<PeopleIcon />}
            label={
              athletesInGroup.length > 0
                ? `${athletesInGroup.length}`.padStart(2, "0")
                : "No athletes"
            }
            variant="outlined"
            sx={{
              mr: 2,
              minWidth: "60px",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleManageClick}
          />
          <ExpandMoreIcon
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "0.3s",
            }}
          />
        </Box>
      </Box>
      <Collapse in={expanded}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Athletes
          </Typography>
          {athletesInGroup.length > 0 ? (
            <List>
              {athletesInGroup.map((athlete) => (
                <AthletePod
                  key={athlete.id}
                  athlete={athlete}
                  showInvite={false}
                />
              ))}
            </List>
          ) : (
            <Typography variant="body2">No athletes in this group</Typography>
          )}
        </Box>
      </Collapse>
      <Form
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fields={formFields}
        onSubmit={handleEditSubmit}
        title="Edit Group Name"
        defaultValues={{ name: group.name }}
      />
    </PodWrapper>
  );
};

export { EventGroupPod };
