import React, { useState, useEffect } from 'react';
import useApiService from '../../api/apiService';
import { Box, Typography, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { WbSunny, Cloud, Opacity, Air } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const WeatherForecastHome = () => {
  const { weather } = useApiService();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [location, setLocation] = useState(null);
  const [locationName, setLocationName] = useState('');

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude
          });
          fetchLocationName(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
          setLocationName("Unknown Location");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLocationName("Unknown Location");
    }
  }, []);

  const fetchLocationName = async (lat, lon) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`);
      const data = await response.json();
      setLocationName(data.address.city || data.address.town || data.address.village || "Unknown Location");
    } catch (error) {
      console.error("Error fetching location name:", error);
      setLocationName("Unknown Location");
    }
  };

  const { data: weatherData, isLoading, error } = useQuery({
    queryKey: ['weather', location?.lat, location?.lon],
    queryFn: async () => {
      if (!location) return null;
      const result = await weather({
        lat: location.lat.toFixed(4),
        lon: location.lon.toFixed(4),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      return result;
    },
    enabled: !!location,
    staleTime: 1000 * 60 * 60, // 1 hour
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    retry: false,
    refetchOnWindowFocus: false,
  });

  const getWeatherIcon = (rainChance) => {
    if (rainChance > 60) return <Opacity />;
    if (rainChance > 30) return <Cloud />;
    return <WbSunny />;
  };

  if (!location) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!weatherData || !weatherData.data.weather || !Array.isArray(weatherData.data.weather) || weatherData.data.weather.length === 0) {
    return null;
  }

  const forecastDays = weatherData.data.weather.slice(1); // Remove today's forecast

  return (
    <Box sx={{ padding: isMobile ? 2 : 0, backgroundColor: '#f5f5f5', borderRadius: 2, boxShadow: 2, margin: 'auto', width: '100%', overflowX: 'auto' }}>
      <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>Weather Forecast for {locationName}</Typography>
      <Grid container spacing={1} sx={{ flexWrap: 'nowrap', minWidth: isMobile ? 'max-content' : 'auto' }}>
        {forecastDays.map((day, index) => {
          const forecastDate = dayjs(day.date);
          return (
            <Grid item xs={12/4} key={index}>
              <Paper elevation={2} sx={{ 
                textAlign: 'center', 
                transition: 'transform 0.3s ease', 
                '&:hover': { transform: 'translateY(-5px)' },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}>
                <Typography variant="caption" fontWeight="bold" noWrap>
                  {forecastDate.format('ddd, MMM D')}
                </Typography>
                <Box sx={{ fontSize: isMobile ? '1.5rem' : '2.5rem', margin: '5px 0' }}>{getWeatherIcon(day.rainChance)}</Box>
                <Typography variant={isMobile ? "body2" : "body1"} fontWeight="bold">{Math.round(day.temp)}°F</Typography>
                <Typography variant="caption" sx={{ fontSize: isMobile ? '0.6rem' : '0.75rem' }}>
                  H: {Math.round(day.tempMax)}° / L: {Math.round(day.tempMin)}°
                </Typography>
                <Typography variant="caption" color="primary" sx={{ fontSize: isMobile ? '0.6rem' : '0.75rem' }}>
                  Rain: {day.rainChance}%
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5, color: 'text.secondary' }}>
                  <Air fontSize="small" />
                  <Typography variant="caption" sx={{ fontSize: isMobile ? '0.6rem' : '0.75rem' }}>
                    {Math.round(day.windSpeed)} mph
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default WeatherForecastHome;
