import React, { useState, useEffect } from 'react';
import {
  TextField,
  Switch,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  Box,
  Typography,
  Collapse,
  Grid,
  FormControl,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ModalWrapper from '../utils/wrappers/modalWrapper';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const StyledFormControl = styled(FormControl)({
  width: '100%',
  marginBottom: '8px',
});

const StyledTextField = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: '40px',
  },
});

const StyledSelect = styled(Select)({
  width: '100%',
  height: '40px',
});

const DesktopForm = ({ open, onClose, fields, onSubmit, title, defaultValues = {}, data = {}, sx = {}, noSubmitButton = false }) => {
  const [formData, setFormData] = useState({ ...defaultValues, ...data });
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [filterTexts, setFilterTexts] = useState({});
  const [selectOpens, setSelectOpens] = useState({});

  useEffect(() => {
    setFormData({ ...defaultValues, ...data });
  }, [defaultValues, data]);

  const handleChange = (name, value) => {
    if (name === 'email') {
      value = value.toLowerCase();
    }
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSelectOpen = (fieldName) => {
    setSelectOpens(prev => ({ ...prev, [fieldName]: true }));
    setFilterTexts(prev => ({ ...prev, [fieldName]: '' }));
  };

  const handleSelectClose = (fieldName) => {
    setSelectOpens(prev => ({ ...prev, [fieldName]: false }));
  };

  const renderField = (field) => {
    if (field.conditional && !field.conditional(formData)) {
      return null;
    }

    switch (field.type) {
      case 'text':
      case 'number':
      case 'email':
      case 'password':
      case 'date':
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body2">{field.label}</Typography>
            </Grid>
            <Grid item xs={8}>
              <StyledFormControl>
                <StyledTextField
                  type={field.type === 'password' ? (showPassword ? 'text' : 'password') : field.type}
                  value={formData[field.name] || ''}
                  onChange={(e) => handleChange(field.name, field.type === 'number' ? parseFloat(e.target.value) : e.target.value)}
                  variant="outlined"
                  size="small"
                  placeholder={field.placeholder}
                  InputProps={field.type === 'password' ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword}
                          edge="end"
                          size="small"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  } : undefined}
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        );
      case 'bool':
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body2">{field.label}</Typography>
            </Grid>
            <Grid item xs={8}>
              <StyledFormControl>
                <Switch
                  checked={formData[field.name] || false}
                  onChange={(e) => handleChange(field.name, e.target.checked)}
                  size="small"
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        );
      case 'singleSelect':
      case 'multiSelect':
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body2">{field.label}</Typography>
            </Grid>
            <Grid item xs={8}>
              <StyledFormControl>
                <StyledSelect
                  sx={{ maxWidth: 300 }}
                  multiple={field.type === 'multiSelect'}
                  value={field.type === 'multiSelect' ? (formData[field.name] || []) : (formData[field.name] || '')}
                  onChange={(e) => handleChange(field.name, e.target.value)}
                  renderValue={(selected) => {
                    if (Array.isArray(selected)) {
                      return selected.map(value => field.options.find(option => option.value === value)?.label).join(', ');
                    } else {
                      return field.options.find(option => option.value === selected)?.label || '';
                    }
                  }}
                  variant="outlined"
                  size="small"
                  open={selectOpens[field.name]}
                  onOpen={() => handleSelectOpen(field.name)}
                  onClose={() => handleSelectClose(field.name)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    disableScrollLock: true,
                    disableAutoFocusItem: true,
                    style: { zIndex: 1302 },
                  }}
                >
                  {field.options.length > 10 && (
                    <MenuItem disableRipple style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                      <TextField
                        size="small"
                        placeholder="Filter options..."
                        value={filterTexts[field.name] || ''}
                        onChange={(e) => {
                          e.stopPropagation();
                          setFilterTexts(prev => ({ ...prev, [field.name]: e.target.value }));
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => {
                          if (e.key !== 'Escape') {
                            e.stopPropagation();
                          }
                        }}
                        fullWidth
                        autoFocus
                      />
                    </MenuItem>
                  )}
                  {field.options
                    .filter(option => option.label.toLowerCase().includes((filterTexts[field.name] || '').toLowerCase()))
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {field.type === 'multiSelect' && (
                          <Checkbox checked={(formData[field.name] || []).indexOf(option.value) > -1} size="small" />
                        )}
                        <ListItemText primary={option.label} primaryTypographyProps={{ variant: 'body2' }} />
                      </MenuItem>
                    ))}
                </StyledSelect>
              </StyledFormControl>
            </Grid>
          </Grid>
        );
      case 'custom':
        return (
          <Box width="100%">
            {field.render(formData, handleChange)}
          </Box>
        );
      default:
        return null;
    }
  };

  const optionalFields = fields.filter(field => field.optional);

  return (
    <ModalWrapper open={open} handleClose={onClose} sx={{ zIndex: 1301, ...sx, backdropFilter: 'blur(1px)' }}>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" align="center">{title}</Typography>
        </Box>
        <form onSubmit={handleSubmit} style={{ overflowY: 'auto', flexGrow: 1 }}>
          {fields.filter(field => !field.optional).map((field) => (
            <Box key={field.name} sx={{ mb: 1 }}>
              {renderField(field)}
            </Box>
          ))}
          {optionalFields.length > 0 && (
            <>
              <Box sx={{ mb: 1 }}>
                <Button
                  onClick={toggleMoreOptions}
                  startIcon={showMoreOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  fullWidth
                >
                  {showMoreOptions ? 'Less Options' : 'More Options'}
                </Button>
              </Box>
              <Collapse in={showMoreOptions}>
                {optionalFields.map((field) => (
                  <Box key={field.name} sx={{ mb: 1 }}>
                    {renderField(field)}
                  </Box>
                ))}
              </Collapse>
            </>
          )}
        </form>
        {!noSubmitButton && (
          <Button type="submit" variant="contained" color="primary" fullWidth size="small" onClick={handleSubmit} sx={{ mt: 2 }}>
            Submit
          </Button>
        )}
      </Box>
    </ModalWrapper>
  );
};

export default DesktopForm;
