import React, { useState, useEffect } from "react";
import Form from "../../../../../components/form/form";
import { Grid, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";

const SetForm = ({ open, onClose, onSave, onDelete, initialSet }) => {
  const [setReps, setSetReps] = useState(initialSet?.reps || 1);
  const [setRecovery, setSetRecovery] = useState(initialSet?.recovery || 0);
  const [setRecoveryUnit] = useState("seconds");

  useEffect(() => {
    if (initialSet) {
      setSetReps(initialSet.reps || 1);
      setSetRecovery(initialSet.recovery || 0);
    }
  }, [initialSet]);

  const handleSubmit = (formData) => {
    const recoveryInSeconds =
      formData.recoveryUnit === "minutes" ? formData.recovery * 60 : formData.recovery;
    onSave({ reps: formData.reps, recovery: recoveryInSeconds });
  };

  const renderRecoveryFields = (formData, handleChange) => {
    if (formData.reps <= 1) return null;

    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Recovery"
            type="number"
            value={formData.recovery}
            onChange={(e) => handleChange("recovery", e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={formData.recoveryUnit}
            exclusive
            onChange={(e, newValue) => {
              if (newValue !== null) {
                handleChange("recoveryUnit", newValue);
              }
            }}
            aria-label="Recovery Unit"
            size="small"
          >
            <ToggleButton value="seconds" aria-label="Seconds">
              Seconds
            </ToggleButton>
            <ToggleButton value="minutes" aria-label="Minutes">
              Minutes
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    );
  };

  const fields = [
    {
      name: "reps",
      label: "Number of Reps (times to perform this set)",
      type: "number",
      required: true,
    },
    {
      name: "recoveryFields",
      type: "custom",
      render: renderRecoveryFields,
    },
  ];

  return (
    <Form
      open={open}
      onClose={onClose}
      fields={fields}
      onSubmit={handleSubmit}
      title={initialSet ? "Edit Set" : "Create Set"}
      defaultValues={{
        reps: setReps,
        recovery: setRecovery,
        recoveryUnit: setRecoveryUnit,
      }}
      data={{
        onDelete: onDelete,
      }}
    />
  );
};

export { SetForm };
