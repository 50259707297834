import React, { useContext, useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, BottomNavigation, BottomNavigationAction, Typography, Divider, Box, IconButton, Menu, MenuItem, Chip } from '@mui/material';
import RosterIcon from '@mui/icons-material/People';
import TrainingPlanIcon from '@mui/icons-material/FitnessCenter';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { AuthContext } from '../../context/authContext';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, useLocation } from 'react-router-dom';
import FormTeam from '../teamUtils/formTeam';
import { FormUser, FormPassword } from '../user';
import Logo from './2.svg';

// Helper function to determine subscription status
const getSubscriptionTier = (team) => {
  if (!team?.subscription || team.subscription.status === 'past_due') {
    return 'Free Plan';
  }
  return team.subscription.plan.includes('pro') ? 'Pro Plan' : 'Free Plan';
};

const SideNav = () => {
  const { logout, user, userType, team } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTeamFormOpen, setIsTeamFormOpen] = useState(false);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [isPasswordFormOpen, setIsPasswordFormOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleTeamClick = () => {
    if (userType === 'coach') {
      setIsTeamFormOpen(true);
    }
  };

  const handleTeamFormClose = () => {
    setIsTeamFormOpen(false);
  };

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const handleUserFormOpen = () => {
    setIsUserFormOpen(true);
    handleSettingsClose();
  };

  const handleUserFormClose = () => {
    setIsUserFormOpen(false);
  };

  const handlePasswordFormOpen = () => {
    setIsPasswordFormOpen(true);
    handleSettingsClose();
  };

  const handlePasswordFormClose = () => {
    setIsPasswordFormOpen(false);
  };

  const navItems = [
    { icon: <HomeIcon />, label: 'Home', onClick: () => navigate('/home'), path: '/home' },
    { icon: <RosterIcon />, label: 'Roster', onClick: () => navigate('/roster'), path: '/roster' },
    { icon: <TrainingPlanIcon />, label: 'Training Plan', onClick: () => navigate('/training-plan'), path: '/training-plan' },
    { divider: true },
    { icon: <LogoutIcon />, label: 'Logout', onClick: handleLogout, path: '/logout' },
  ];

  const isSelected = (path) => {
    return location.pathname.startsWith(path) && path !== '/logout';
  };

  const SideNavContent = () => (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ margin: 2 }}>
        <Box textAlign="center">
          <img src={Logo} alt="Track Tools Pro" style={{ height: '40px' }} />
        </Box>
      {team && (
        <Box display="flex" justifyContent="center" sx={{ mb: 1, width: '100%' }}>
          <Chip size="small" label={getSubscriptionTier(team)} color="primary" sx={{ width: '80%', fontSize: '0.75rem' }}/>
        </Box>
      )}
      </Box>
      {team && team.name && (
        <Box 
          sx={{ 
            width: '100%', 
            backgroundColor: 'primary.main', 
            py: 1, 
            mb: 1, 
            cursor: userType === 'coach' ? 'pointer' : 'default'
          }}
          onClick={handleTeamClick}
        >
          <Typography variant="body2" align="center" sx={{ color: 'primary.contrastText' }}>
            {team.name}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ px: 2, mb: 2 }}>
        <Box flexGrow={1} display="flex" justifyContent="center">
          <Typography variant="caption" sx={{ color: 'white' }}>
            Welcome, {userType === 'coach' ? `Coach ${user.lastName}` : `${user.firstName}`}!
          </Typography>
        </Box>
        <IconButton onClick={handleSettingsClick} size="small">
          <SettingsIcon fontSize="small" sx={{ color: 'white' }} />
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {navItems.map((item, index) => (
          item.divider ? (
            <Divider key={index} sx={{ mt: 2, mb: 2 }} />
          ) : (
            <ListItem 
              key={index} 
              onClick={item.onClick}
              sx={{ cursor: 'pointer' }}
            >
              <ListItemIcon sx={{ color: isSelected(item.path) ? 'primary.main' : 'white' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} sx={{ color: isSelected(item.path) ?  'primary.main' : 'white' }} />
            </ListItem>
          )
        ))}
      </List>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSettingsClose}
      >
        <MenuItem onClick={handleUserFormOpen}>Update User Info</MenuItem>
        <MenuItem onClick={handlePasswordFormOpen}>Update Password</MenuItem>
      </Menu>
    </>
  );

  const BottomNav = () => (
    <BottomNavigation
      showLabels
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1000,
        backgroundColor: (t) => `${t.palette.background.dark}`,
      }}
      value={location.pathname}
    >
      {navItems.slice(0, 3).map((item, index) => (
        <BottomNavigationAction 
          key={index} 
          label={item.label} 
          icon={item.icon} 
          onClick={item.onClick} 
          value={item.path}
          sx={{ color: isSelected(item.path) ? 'primary.main' : 'white' }}
        />
      ))}
      <BottomNavigationAction 
        label="Menu"
        icon={<MenuIcon />}
        onClick={toggleDrawer}
        sx={{ color: 'white' }}
      />
    </BottomNavigation>
  );

  return (
    <>
      {isMobile ? (
        <>
          <BottomNav />
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={toggleDrawer}
            PaperProps={{
              sx: {
                backgroundColor: (t) => `${t.palette.background.dark}`,
                color: 'white',
                zIndex: 10001,
              }
            }}
          >
            <SideNavContent />
          </Drawer>
        </>
      ) : (
        <Drawer
          variant="permanent"
          anchor="left"
          PaperProps={{
            sx: {
              border: 'none',
              borderRight: (theme) => `1px solid ${theme.palette.border.main}`,
              maxWidth: 250,
              minWidth: 240,
              backgroundColor: (t) => `${t.palette.background.dark}`,
            }
          }}
        >
          <SideNavContent />
        </Drawer>
      )}
      {userType === 'coach' && (
        <FormTeam
          open={isTeamFormOpen}
          handleClose={handleTeamFormClose}
          onTeamUpdated={() => {}} // You might want to handle team updates here
          team={team}
        />
      )}
      <FormUser
        open={isUserFormOpen}
        handleClose={handleUserFormClose}
      />
      <FormPassword
        open={isPasswordFormOpen}
        handleClose={handlePasswordFormClose}
      />
    </>
  );
};

export default SideNav;