import React, { useState, useCallback, useMemo } from 'react';
import Form from '../../../form/form';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tabs,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from "@mui/material";
import useApiService from "../../../../api/apiService";
import { useTheme } from "@mui/material/styles";

const FormAthlete = ({
  open,
  handleClose,
  athlete,
}) => {
  const theme = useTheme();
  const [formData, setFormData] = useState(
    athlete || {
      firstName: "",
      lastName: "",
      email: "",
      birthDate: "",
      phone: "",
      grade: "freshman",
      fScore: 0,
      athleteTypes: [],
      gender: "male",
      tffrsLink: "",
    }
  );
  const [raceTime, setRaceTime] = useState("");
  const [raceType, setRaceType] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  const { findScore, useCreateAthlete, useUpdateAthlete } = useApiService();

  const createAthleteMutation = useCreateAthlete();
  const updateAthleteMutation = useUpdateAthlete();

  const handleSubmit = async (submittedData) => {
    try {
      const dataToSubmit = { ...formData, ...submittedData };
      if (athlete) {
        await updateAthleteMutation.mutateAsync({
          id: athlete.id,
          data: dataToSubmit,
        });
      } else {
        await createAthleteMutation.mutateAsync(dataToSubmit);
      }
      handleClose();
    } catch (error) {
      console.error("Error saving athlete:", error);
    }
  };

  const convertTimeToSeconds = useCallback((timeString) => {
    const parts = timeString.split(":").map(Number);
    let seconds = 0;
    if (parts.length === 3) {
      seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
      seconds = parts[0] * 60 + parts[1];
    } else if (parts.length === 1) {
      seconds = parts[0];
    }
    return seconds;
  }, []);

  const guessEventByTime = useCallback((timeInSeconds) => {
    if (timeInSeconds >= 7200) return "marathon";
    if (timeInSeconds >= 3600) return "half_marathon";
    if (timeInSeconds >= 1680) return "10000";
    if (timeInSeconds >= 860) return "5000";
    if (timeInSeconds >= 480) return "3000";
    if (timeInSeconds >= 240) return "mile";
    if (timeInSeconds >= 180) return "1000";
    return "800";
  }, []);

  const handleFindScore = useCallback(async () => {
    try {
      const timeInSeconds = convertTimeToSeconds(raceTime);
      const response = await findScore({
        timeInSeconds: timeInSeconds,
        race: raceType,
      });
      if (response.data && response.data.fScore) {
        const newFScore = Number(response.data.fScore).toFixed(2);
        setFormData(prevData => ({ ...prevData, fScore: newFScore }));
        setActiveTab(1);
      }
    } catch (error) {
      console.error("Error finding score:", error);
    }
  }, [convertTimeToSeconds, findScore, raceTime, raceType]);

  const handleRaceTimeChange = useCallback((e) => {
    const newRaceTime = e.target.value;
    setRaceTime(newRaceTime);
    if (newRaceTime) {
      const timeInSeconds = convertTimeToSeconds(newRaceTime);
      const guessedRaceType = guessEventByTime(timeInSeconds);
      setRaceType(guessedRaceType);
    }
  }, [convertTimeToSeconds, guessEventByTime]);

  const renderGenderSelector = useCallback((data, onChange) => (
    <Box display="flex" justifyContent="center" width="100%">
      <ToggleButtonGroup
        color="primary"
        value={data.gender}
        exclusive
        onChange={(e, newGender) => {
          if (newGender !== null) {
            onChange('gender', newGender);
          }
        }}
        aria-label="Gender"
        fullWidth
      >
        <ToggleButton
          value="male"
          aria-label="male"
          style={{
            backgroundColor:
              data.gender === "male"
                ? theme.palette.gender.male
                : "inherit",
            color: data.gender === "male" ? "white" : "inherit",
            flex: 1,
          }}
        >
          Male
        </ToggleButton>
        <ToggleButton
          value="female"
          aria-label="female"
          style={{
            backgroundColor:
              data.gender === "female"
                ? theme.palette.gender.female
                : "inherit",
            color: data.gender === "female" ? "white" : "inherit",
            flex: 1,
          }}
        >
          Female
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  ), [theme.palette.gender.male, theme.palette.gender.female]);

  const renderFScoreCalculator = useCallback((data, onChange) => (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
      >
        <Tab label="Calculate FScore" />
        <Tab label="FScore" />
      </Tabs>
      {activeTab === 0 && (
        <Box display="flex" flexDirection="row" alignItems="center" padding={1}>
          <TextField
            size="small"
            name="raceTime"
            label="Time (HH:MM:SS)"
            value={raceTime}
            onChange={handleRaceTimeChange}
            placeholder="00:00:00"
            sx={{ marginRight: 1, width: '30%' }}
          />
          <FormControl size="small" sx={{marginRight: 1, width: '30%'}}>
            <InputLabel id="race-type-label">Race Type</InputLabel>
            <Select
              labelId="race-type-label"
              value={raceType}
              onChange={(e) => setRaceType(e.target.value)}
            >
              <MenuItem value="marathon">Marathon</MenuItem>
              <MenuItem value="half_marathon">Half Marathon</MenuItem>
              <MenuItem value="10000">10000m</MenuItem>
              <MenuItem value="8000">8000m</MenuItem>
              <MenuItem value="5000">5000m</MenuItem>
              <MenuItem value="3000">3000m</MenuItem>
              <MenuItem value="mile">Mile</MenuItem>
              <MenuItem value="1500">1500m</MenuItem>
              <MenuItem value="1000">1000m</MenuItem>
              <MenuItem value="800">800m</MenuItem>
            </Select>
          </FormControl>
          <Button
            onClick={handleFindScore}
            variant="contained"
            color="primary"
            size="small"
          >
            Calculate
          </Button>
        </Box>
      )}
      {activeTab === 1 && (
        <TextField
          fullWidth
          name="fScore"
          label="FScore"
          type="number"
          value={data.fScore}
          onChange={(e) => {
            const newFScore = e.target.value;
            onChange('fScore', newFScore);
          }}
          required
        />
      )}
    </Box>
  ), [activeTab, raceTime, raceType, handleFindScore, handleRaceTimeChange]);

  const fields = useMemo(() => [
    { name: 'firstName', label: 'First Name', type: 'text', required: true },
    { name: 'lastName', label: 'Last Name', type: 'text', required: true },
    { name: 'grade', label: 'Grade', type: 'singleSelect', options: [
      { value: 'freshman', label: 'Freshman' },
      { value: 'sophomore', label: 'Sophomore' },
      { value: 'junior', label: 'Junior' },
      { value: 'senior', label: 'Senior' },
      { value: '5th_year', label: '5th Year' },
      { value: '6th_year', label: '6th Year' },
      { value: '6th', label: '6th Grade' },
      { value: '7th', label: '7th Grade' },
      { value: '8th', label: '8th Grade' },
      { value: '9th', label: '9th Grade' },
      { value: '10th', label: '10th Grade' },
      { value: '11th', label: '11th Grade' },
      { value: '12th', label: '12th Grade' },
      { value: 'alumni', label: 'Alumni' },
      { value: 'other', label: 'Other' },
    ]},
    { name: 'athleteTypes', label: 'Athlete Types', type: 'multiSelect', options: [
      { value: 'distance', label: 'Distance' },
      { value: 'sprints', label: 'Sprints' },
      { value: 'jumps', label: 'Jumps' },
      { value: 'throws', label: 'Throws' },
      { value: 'pole_vault', label: 'Pole Vault' },
      { value: 'multi', label: 'Multi' },
    ]},
    { name: 'gender', label: 'Gender', type: 'custom', render: renderGenderSelector },
    { name: 'fScore', label: 'Distnace - FScore', type: 'custom', render: renderFScoreCalculator },
    { name: 'email', label: 'Email', type: 'email', optional: !athlete },
    { name: 'tffrsLink', label: 'TFFRS Link', type: 'text', optional: !athlete },
  ], [athlete, renderGenderSelector, renderFScoreCalculator]);

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={fields}
      onSubmit={handleSubmit}
      title={athlete ? "Edit Athlete" : "Add New Athlete"}
      defaultValues={formData}
      sx={{ zIndex: 100 }}
    />
  );
};

export default FormAthlete;
