import React, { useContext } from 'react';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import { AuthContext } from '../../../context/authContext';
import SubscriptionForm from '../../../components/stripe/subscriptionForm';
import { useNavigate } from 'react-router-dom';

const TierFeature = ({ icon, text }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
    {icon}
    <Typography variant="body2" sx={{ ml: 1 }}>{text}</Typography>
  </Box>
);

const SubTiers = () => {
  const { team, token, userType } = useContext(AuthContext);
  const [openSubscriptionForm, setOpenSubscriptionForm] = React.useState(false);
  const navigate = useNavigate();

  const handleOpenSubscriptionForm = () => {
    if (!token) {
      navigate('/login/create-account');
    } else {
      setOpenSubscriptionForm(true);
    }
  };

  const handleCloseSubscriptionForm = () => {
    setOpenSubscriptionForm(false);
  };

  const tiers = [
    {
      name: "Free Plan",
      icon: <CheckCircleOutlineIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      features: [
        { icon: <FitnessCenterIcon fontSize="small" />, text: "Workout Generation" },
        { icon: <PeopleIcon fontSize="small" />, text: "10 Athlete Profiles" },
        { icon: <PersonIcon fontSize="small" />, text: "1 Coach Profile" },
        { icon: <EventIcon fontSize="small" />, text: "Training Plan" },
        { icon: <EventIcon fontSize="small" />, text: "Event Groups" },
      ],
    },
    {
      name: "Pro Plan",
      icon: <StarIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      features: [
        { icon: <FitnessCenterIcon fontSize="small" />, text: "All Free Plan Features" },
        { icon: <PeopleIcon fontSize="small" />, text: "Unlimited Athlete Profiles" },
        { icon: <PersonIcon fontSize="small" />, text: "Unlimited Coach Profiles" },
        { icon: <EventIcon fontSize="small" />, text: "Training Log (coming soon)" },
      ],
    },
  ];

  const getCurrentPlan = () => {
    if (team?.subscription?.plan === "plan_pro_yearly" || team?.subscription?.plan === "plan_pro_monthly") {
      if (team?.subscription?.status === "past_due") {
        return "Free Plan";
      }
      return "Pro Plan";
    } else {
      return "Free Plan";
    }
  };

  const currentPlan = getCurrentPlan();

  if (userType && userType !== 'coach') {
    return null;
  }

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Choose Your Plan
      </Typography>
      <Grid container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
        {tiers.map((tier, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3, 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                border: currentPlan === tier.name ? '2px solid #1976d2' : 'none'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                {tier.icon}
              </Box>
              <Typography variant="h5" align="center" gutterBottom>
                {tier.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                {tier.features.map((feature, idx) => (
                  <TierFeature key={idx} icon={feature.icon} text={feature.text} />
                ))}
              </Box>
              {currentPlan === tier.name && (
                <Typography variant="subtitle1" align="center" color="primary" sx={{ mt: 2 }}>
                  Current Plan
                </Typography>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleOpenSubscriptionForm}
          size="large"
        >
          {!token ? 'Create Account to Subscribe' : currentPlan === "Pro Plan" ? 'View Subscription Details' : 'Upgrade to Pro'}
        </Button>
      </Box>
      {token && <SubscriptionForm open={openSubscriptionForm} onClose={handleCloseSubscriptionForm} />}
    </Box>
  );
};

export default SubTiers;
