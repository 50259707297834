import React from 'react';
import { Box, Grid } from '@mui/material';
import { AthletePod } from '../../../../components/pods/athletePod/athletePod';

const TableAthlete = ({ athletes, handleOpenForm, handleDeleteClick, handleOpenPaceChartDialog, handleOpenRacePredictionDialog }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid>
        {athletes.map((athlete) => (
          <Grid item xs={12} key={athlete.id}>
            <AthletePod
              athlete={athlete}
              showFields={['name', 'email', 'fScore', 'grade', 'gender', 'athleteTypes', 'tffrsLink']}
              options={true}
              handleOpenForm={() => handleOpenForm(athlete)}
              handleDeleteClick={() => handleDeleteClick(athlete)}
              handleOpenPaceChartDialog={() => handleOpenPaceChartDialog(athlete)}
              handleOpenRacePredictionDialog={() => handleOpenRacePredictionDialog(athlete)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export { TableAthlete };
