import React from 'react';
import { TextField, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledFormControl = styled('div')({
  width: '100%',
  marginBottom: '8px',
});

const StyledTextField = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    height: '40px',
  },
});

const CustomTextField = ({ label, ...props }) => {
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={4}>
        <Typography variant="body2">{label}</Typography>
      </Grid>
      <Grid item xs={8}>
        <StyledFormControl>
          <StyledTextField
            variant="outlined"
            size="small"
            {...props}
          />
        </StyledFormControl>
      </Grid>
    </Grid>
  );
};

export default CustomTextField;
