import React from "react";
import Form from "../../../../../components/form/form";

const WorkoutForm = ({
  open,
  handleClose,
  selectedWorkout,
  handleSaveWorkout,
}) => {
  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "text",
    },
    {
      name: "workout_type",
      label: "Workout Type",
      type: "singleSelect",
      required: true,
      options: [
        { value: "distance", label: "Distance" },
        { value: "sprints", label: "Sprints" },
        { value: "jumps", label: "Jumps" },
        { value: "hurdles", label: "Hurdles" },
        { value: "throws", label: "Throws" },
        { value: "pole vault", label: "Pole Vault" },
        { value: "recovery", label: "Recovery" },
        { value: "supplementary", label: "Supplementary" },
        { value: "strength", label: "Strength" },
        { value: "easy", label: "Easy" },
      ],
    },
  ];

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={fields}
      onSubmit={handleSaveWorkout}
      title={selectedWorkout ? "Edit Workout" : "Create Workout"}
      defaultValues={selectedWorkout || {}}
    />
  );
};

export { WorkoutForm };
