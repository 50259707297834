import React, { useState, useEffect } from "react";
import Form from "../../../../../components/form/form";
import { Grid, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CustomTextField } from "../../../../../components/inputs";

const repPaceOptions = [
  { value: "recovery", label: "Recovery" },
  { value: "easy", label: "Easy" },
  { value: "moderate", label: "Moderate" },
  { value: "tempo", label: "Tempo" },
  { value: "threshold", label: "Threshold" },
  { value: "cv", label: "CV" },
  { value: "aerobic_power", label: "Aerobic Power" },
  { value: "vo2_max", label: "VO2 Max" },
  { value: "anaerobic_endurance_mile", label: "Anaerobic Endurance Mile" },
  { value: "anaerobic_endurance_1500", label: "Anaerobic Endurance 1500" },
  { value: "anaerobic_power", label: "Anaerobic Power" },
  { value: "speed_endurance", label: "Speed Endurance" },
  { value: "pace_60", label: "60m PR" },
  { value: "pace_100", label: "100m PR" },
  { value: "pace_200", label: "200m PR" },
  { value: "pace_300", label: "300m PR" },
  { value: "pace_400", label: "400m PR" },
  { value: "pace_60H", label: "60H PR" },
  { value: "pace_100H", label: "100H PR" },
  { value: "pace_110H", label: "110H PR" },
  { value: "pace_300H", label: "300H PR" },
  { value: "pace_400H", label: "400H PR" },
  { value: "pace_600", label: "600m PR" },
  { value: "pace_800", label: "800m PR" },
  { value: "pace_1000", label: "1000m PR" },
  { value: "pace_1500", label: "1500m PR" },
  { value: "pace_MILE", label: "Mile PR" },
  { value: "pace_1600", label: "1600m PR" },
  { value: "pace_3000", label: "3000m PR" },
  { value: "pace_3000S", label: "3k Steeplechase PR" },
  { value: "pace_3200", label: "3200m PR" },
  { value: "pace_5000", label: "5000m PR" },
  { value: "pace_10000", label: "10,000m PR" },
  { value: "pace_4K_XC", label: "4K Cross Country PR" },
  { value: "pace_5K_XC", label: "5K Cross Country PR" },
  { value: "pace_6K_XC", label: "6K Cross Country PR" },
  { value: "pace_7K_XC", label: "7K Cross Country PR" },
  { value: "pace_8K_XC", label: "8K Cross Country PR" },
  { value: "pace_10K_XC", label: "10K Cross Country PR" },
  { value: "pace_4_MILE_XC", label: "4 Mile Cross Country PR" },
];

const distanceUnitOptions = [
  { value: "meters", label: "Meters" },
  { value: "kilometers", label: "Kilometers" },
  { value: "miles", label: "Miles" },
  { value: "time", label: "Time" },
];

const RepForm = ({ open, onClose, onSave, initialData, onDelete }) => {
  const [formData, setFormData] = useState({
    distance: "",
    distanceUnit: "meters",
    pace: "",
    reps: "",
    pacePercentage: 100,
    recovery: 0,
    recoveryUnit: "seconds",
    timeInput: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        distance: initialData.distance || "",
        distanceUnit: initialData.distanceUnit || "meters",
        pace: initialData.pace || "",
        reps: initialData.reps || "",
        pacePercentage: initialData.pacePercentage || 100,
        recovery: initialData.recovery || 0,
        recoveryUnit: "seconds",
        timeInput: initialData.timeInSeconds ? formatSecondsToTimeString(initialData.timeInSeconds) : "",
      });
    }
  }, [initialData]);

  const handleSubmit = (formData) => {
    const recoveryInSeconds =
      formData.recoveryUnit === "minutes" ? formData.recovery * 60 : formData.recovery;
    const repData = {
      distance: formData.distance,
      distance_unit: formData.distanceUnit,
      pace: formData.pace,
      reps: formData.reps,
      pace_percentage: formData.pacePercentage,
      recovery: recoveryInSeconds,
      time_in_seconds: formData.distanceUnit === "time" ? parseTimeStringToSeconds(formData.timeInput) : undefined,
    };
    onSave(repData);
    setFormData({
      distance: "",
      distanceUnit: "meters",
      pace: "",
      reps: "",
      pacePercentage: 100,
      recovery: 0,
      recoveryUnit: "seconds",
      timeInput: "",
    });
    onClose();
  };

  const formatSecondsToTimeString = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours > 0 ? hours + ':' : ''}${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const parseTimeStringToSeconds = (timeString) => {
    const parts = timeString.split(':').map(Number);
    let seconds = 0;
    if (parts.length === 3) {
      seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
      seconds = parts[0] * 60 + parts[1];
    } else if (parts.length === 1) {
      seconds = parts[0];
    }
    return seconds;
  };

  const renderRecoveryFields = (formData, handleChange) => {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Recovery"
            type="number"
            value={formData.recovery}
            onChange={(e) => handleChange("recovery", e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={formData.recoveryUnit}
            exclusive
            onChange={(e, newValue) => {
              if (newValue !== null) {
                handleChange("recoveryUnit", newValue);
              }
            }}
            aria-label="Recovery Unit"
            size="small"
          >
            <ToggleButton value="seconds" aria-label="Seconds">
              Seconds
            </ToggleButton>
            <ToggleButton value="minutes" aria-label="Minutes">
              Minutes
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    );
  };

  const fields = [
    {
      name: "distanceOrTime",
      type: "custom",
      render: (formData, handleChange) => 
        formData.distanceUnit === "time" ? (
          <CustomTextField
            fullWidth
            label="Time (HH:MM:SS)"
            value={formData.timeInput}
            onChange={(e) => handleChange("timeInput", e.target.value)}
            variant="outlined"
            size="small"
            placeholder="e.g. 14:20 or 2:30:10"
          />
        ) : (
          <CustomTextField
            fullWidth
            label="Distance"
            type="number"
            value={formData.distance}
            onChange={(e) => handleChange("distance", e.target.value)}
            variant="outlined"
            size="small"
          />
        ),
    },
    {
      name: "distanceUnit",
      label: "Distance Unit",
      type: "singleSelect",
      required: true,
      options: distanceUnitOptions,
    },
    {
      name: "pace",
      label: "Pace",
      type: "singleSelect",
      required: true,
      options: repPaceOptions,
    },
    {
      name: "pacePercentage",
      label: "Pace Percentage",
      type: "number",
      required: true,
    },
    {
      name: "reps",
      label: "Reps",
      type: "number",
      required: true,
    },
    {
      name: "recoveryFields",
      type: "custom",
      render: renderRecoveryFields,
    },
  ];

  return (
    <Form
      open={open}
      onClose={onClose}
      fields={fields}
      onSubmit={handleSubmit}
      title={initialData ? "Edit Rep" : "Create Rep"}
      defaultValues={formData}
      onDelete={initialData && initialData.id ? () => onDelete(initialData.id) : undefined}
    />
  );
};

export { RepForm };
