import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import PageWrapper from '../pageWrapper/pageWrapper';
import { AthleteSection } from './_components/athletesSection';
import { EventGroupSection } from './_components/eventGroupSection';
import { CoachesSection } from './_components/coachesSection';
import { AuthContext } from '../../context/authContext';

const getTabs = (userType) => {
  const tabs = [
    { label: "Athletes", path: "athletes" },
    { label: "Coaches", path: "coaches" },
  ];

  if (userType === 'coach') {
    tabs.push(
      { label: "Training Groups", path: "training-groups" },
      { label: "Alumni", path: "alumni" }
    );
  }

  return tabs;
};

function Roster() {
  const { userType } = useContext(AuthContext);
  const tabs = getTabs(userType);

  return (
    <PageWrapper title="Roster Management" tabs={tabs} rootUrl="/roster">
        <Box sx={{ padding: 2 }}>
          <Routes>
            <Route index element={<Navigate to="athletes" replace />} />
            <Route path="athletes" element={
              <>
                <Typography variant="h5" component="h2" gutterBottom>
                  Team Roster
                </Typography>
                <AthleteSection />
              </>
            } />
            {userType === 'coach' && (
              <>
                <Route path="training-groups" element={
                  <>
                    <Typography variant="h5" component="h2" gutterBottom>
                      Training Groups
                    </Typography>
                    <EventGroupSection />
                  </>
                } />
                <Route path="alumni" element={
                  <>
                    <Typography variant="h5" component="h2" gutterBottom>
                      Alumni Section
                    </Typography>
                    <div>Coming soon...</div>
                  </>
                } />
              </>
            )}
            <Route path="coaches" element={
                  <CoachesSection />
            } />
          </Routes>
        </Box>
    </PageWrapper>
  );
}

export default Roster;