import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AthletePod } from "../../../../../components/pods";
import useApiService from "../../../../../api/apiService";
import Form from "../../../../../components/form/form";

const SelectAthlete = ({
  open,
  onClose,
  selectedGroup,
  allAthletes,
  selectedAthletes,
  onToggleAthlete,
  onSave,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const filteredAthletes = allAthletes.filter((athlete) =>
    `${athlete.firstName} ${athlete.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const { queryClient } = useApiService();

  function handleSave() {
    onSave(selectedAthletes);
    setTimeout(() => {
      queryClient.invalidateQueries(["eventGroups"]);
    }, 1500);
  }

  const renderContent = (formData, handleChange) => (
    <>
      <TextField
        autoFocus
        margin="dense"
        label="Search Athletes"
        type="search"
        fullWidth
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Box sx={{ maxHeight: 500, overflow: "auto" }}>
        <Grid container spacing={0}>
          {filteredAthletes.map((athlete) => (
            <Grid item xs={12} sm={isSmallScreen ? 12 : 6} key={athlete.id}>
              <AthletePod
                athlete={athlete}
                selectable
                selected={selectedAthletes.includes(athlete.id)}
                onSelectChange={(selected) => onToggleAthlete(athlete.id)}
                showInvite={false}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {filteredAthletes.length === 0 && (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <Typography variant="body2" color="text.secondary">
            No athletes found
          </Typography>
        </Box>
      )}
    </>
  );

  const fields = [
    {
      name: "athleteSelection",
      type: "custom",
      render: renderContent,
    },
  ];

  return (
    <Form
      open={open}
      onClose={onClose}
      fields={fields}
      onSubmit={handleSave}
      title={`Manage Athletes in ${selectedGroup?.name}`}
    />
  );
};

export { SelectAthlete };
