import { createTheme } from '@mui/material/styles';

const subscirptionColors = {
  free: '#009ACD',
  coach: '#008000',
  team: '#800080', // should consider pick your own color on team for this tier
};

const lightTheme = {
  primary: {
    main: subscirptionColors.free, // A shade of blue
  },
  secondary: {
    main: '#DC004E', // A shade of pink
  },
  error: {
    main: '#F44336', // A shade of red
  },
  warning: {
    main: '#FFA726', // A shade of orange
  },
  info: {
    main: '#29B6F6', // A light blue
  },
  success: {
    main: '#66BB6A', // A shade of green
  },
  background: {
    default: '#FAFAFA', // Very light grey
    paper: '#F5F5F5', // Slightly darker off-white
    paperSecondary: '#EBEBEB', // Slightly darker off-white
    paperDark: '#D3D3D3', // Darker shade of light grey
    dark: '#333333', // Dark grey
  },
  text: {
    primary: '#212121', // Very dark grey, almost black
    secondary: '#757575', // Medium grey
  },
  gender: {
    male: '#1976D2', // A shade of blue
    female: '#FF1493', // A slightly lighter shade of pink
  },
  border: {
    main: subscirptionColors.free, // Darker grey
  },
};

const darkTheme = {
  primary: {
    main: subscirptionColors.free, // A lighter shade of blue for dark mode
  },
  secondary: {
    main: '#F48FB1', // A lighter shade of pink for dark mode
  },
  error: {
    main: '#FF8A80', // A lighter shade of red for dark mode
  },
  warning: {
    main: '#FFD180', // A lighter shade of orange for dark mode
  },
  info: {
    main: '#81D4FA', // A lighter shade of blue for dark mode
  },
  success: {
    main: '#A5D6A7', // A lighter shade of green for dark mode
  },
  background: {
    default: '#2A2A2A', // Slightly lighter dark grey
    paper: '#1E1E1E', // Dark grey
    paperDark: '#E0E0E0', // Slightly darker off-white
    dark: '#333333', // Dark grey
  },
  text: {
    primary: '#FFFFFF', // White
    secondary: '#B0BEC5', // Light grey
  },
  gender: {
    male: '#1976D2', // A shade of blue
    female: '#FF1493', // A slightly lighter shade of pink
  },
  border: {
    main: subscirptionColors.free, // Slightly darker off-white
  },
};

const createAppTheme = () => {
  return createTheme({
    palette: {
      mode: 'light',
      ...lightTheme,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: lightTheme.border.main,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: lightTheme.border.main,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '20px',
            border: `1px solid ${lightTheme.border.main}`,
          },
        },
      },
    },
  });
};

export default createAppTheme;
export { lightTheme, darkTheme };