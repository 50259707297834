import React, { useState, useContext } from 'react';
import { Box, Grid, Paper, Typography, TextField, Button } from '@mui/material';
import { AuthContext } from '../../context/authContext';
import useApiService from '../../api/apiService';
import loginTrack from './loginTrack.webp'; // Import the image from the same folder
import { useNavigate, useLocation } from 'react-router-dom';

const LogInSignUpPage = () => {
  const { login } = useContext(AuthContext);
  const apiService = useApiService();
  const navigate = useNavigate();
  const location = useLocation();
  const isCreateAccount = location.pathname === '/login/create-account';
  const [isLogin, setIsLogin] = useState(!isCreateAccount);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [robotField1, setRobotField1] = useState('');
  const [robotField2, setRobotField2] = useState('');

  const { mutate: loginUser } = apiService.useLoginUser();
  const { mutate: registerUser } = apiService.useRegisterUser();

  const handleSwitchMode = () => {
    setIsLogin(!isLogin);
    navigate(isLogin ? '/login/create-account' : '/login');
  };

  const handleLogin = async () => {
    if (robotField1 || robotField2) {
      console.log('Bot detected');
      return;
    }
    loginUser({ email, password }, {
      onSuccess: (response) => {
        const { user, token, type, team } = response.data;
        login(user, token, type, team);
      },
      onError: (error) => {
        console.error('Login failed', error);
      }
    });
  };

  const handleSignUp = async () => {
    if (robotField1 || robotField2) {
      console.log('Bot detected');
      return;
    }
    if (password !== confirmPassword) {
      console.log('Passwords do not match');
      return;
    }
    registerUser({ email, password, first_name: firstName, last_name: lastName }, {
      onSuccess: (response) => {
        const { user, token, type, team } = response.data;
        login(user, token, type, team);
      },
      onError: (error) => {
        console.error('Sign up failed', error);
      }
    });
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${loginTrack})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.background.default,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: (t) => `${t.palette.primary.main}50`,
          },
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ backgroundColor: (t) => `${t.palette.background.dark}` }}>
      <Box
          sx={{
            my: 8,
            mx: 4,
            p: 4,
            mt: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '24px',
            borderColor: (t) => `${t.palette.border.main}`,
            backgroundColor: (t) => `${t.palette.background.paperSecondary}`,
          }}
        >
          <Typography variant="body1">
            {isLogin ? 'Login' : 'Sign Up'}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete={isLogin ? "current-password" : "new-password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="phone"
            label="Required Field - Address"
            type="text"
            id="robotField1"
            value={robotField1}
            onChange={(e) => setRobotField1(e.target.value)}
            sx={{ display: 'none' }}
          />
          <TextField
            margin="normal"
            fullWidth
            name="phone"
            label="Required Field - phone"
            type="text"
            id="robotField2"
            value={robotField2}
            onChange={(e) => setRobotField2(e.target.value)}
            sx={{ display: 'none' }}
          />
          {!isLogin && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="firstName"
                label="First Name"
                type="text"
                id="firstName"
                autoComplete="given-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="lastName"
                label="Last Name"
                type="text"
                id="lastName"
                autoComplete="family-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={isLogin ? handleLogin : handleSignUp}
            sx={{ mt: 3, mb: 2 }}
          >
            {isLogin ? 'Login' : 'Sign Up'}
          </Button>
          <Button onClick={handleSwitchMode} sx={{ mt: 2 }}>
            {isLogin ? 'Sign Up' : 'Login'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LogInSignUpPage;
