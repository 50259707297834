import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
  Badge,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import dayjs from "dayjs";
import { Day } from "./_components";
import useApiService from "../../api/apiService";
import MenuIcon from "../utils/menuIcon/menuIcon";
import { SelectAthlete } from "../../pages/roster/_components/eventGroupSection/_components/selectAthlete";
import { SelectEventGroup } from "./_components/selectEventGroup";

const Calander = ({ ModalComponent, DisplayModels }) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs().startOf("month"));
  const [selectedDate, setSelectedDate] = useState(null);
  const [isBulkSelectMode, setIsBulkSelectMode] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSelectAthleteOpen, setIsSelectAthleteOpen] = useState(false);
  const [isSelectEventGroupOpen, setIsSelectEventGroupOpen] = useState(false);
  const [selectedAthleteIds, setSelectedAthleteIds] = useState([]);
  const [selectedEventGroupIds, setSelectedEventGroupIds] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { useCalanders, useAthletes, useEventGroups } = useApiService();
  const start_date = currentMonth.startOf("month").format("YYYY-MM-DD");
  const end_date = currentMonth.endOf("month").format("YYYY-MM-DD");
  const queryParams = {
    startDate: start_date,
    endDate: end_date,
    modelNames: DisplayModels,
    ids: selectedAthleteIds,
  };
  const { data: calanderData } = useCalanders(queryParams);
  const { data: athletes } = useAthletes();
  const { data: eventGroups } = useEventGroups();

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.add(1, "month"));
  };

  const handleDayClick = (day) => {
    if (isBulkSelectMode) {
      const dateString = day.format("YYYY-MM-DD");
      setSelectedDates((prev) =>
        prev.includes(dateString)
          ? prev.filter((d) => d !== dateString)
          : [...prev, dateString]
      );
    } else {
      setSelectedDate(day);
      setIsModalOpen(true);
    }
  };

  const handleBulkSelectComplete = () => {
    if (selectedDates.length > 0) {
      setIsModalOpen(true);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBulkSelectToggle = () => {
    setIsBulkSelectMode(!isBulkSelectMode);
    if (isBulkSelectMode) {
      setSelectedDates([]);
    }
    handleMenuClose();
  };

  const handleCancelBulkAdd = () => {
    setIsBulkSelectMode(false);
    setSelectedDates([]);
  };

  const handleViewAsClick = () => {
    setIsSelectAthleteOpen(true);
    handleMenuClose();
  };

  const handleViewAsEventGroupClick = () => {
    setIsSelectEventGroupOpen(true);
    handleMenuClose();
  };

  const handleToggleAthlete = (athleteId) => {
    setSelectedAthleteIds((prev) =>
      prev.includes(athleteId)
        ? prev.filter((id) => id !== athleteId)
        : [...prev, athleteId]
    );
  };

  const handleSaveSelectedAthletes = () => {
    setIsSelectAthleteOpen(false);
  };

  const handleSaveSelectedEventGroups = (eventGroupIds) => {
    setSelectedEventGroupIds(eventGroupIds);
    const selectedGroups = eventGroups.filter((group) =>
      eventGroupIds.includes(group.id)
    );
    const athleteIds = new Set();
    selectedGroups.forEach((group) => {
      group.athletes.forEach((athlete) => athleteIds.add(athlete.id));
    });
    setSelectedAthleteIds(Array.from(athleteIds));
    setIsSelectEventGroupOpen(false);
  };

  const generateDaysInMonth = () => {
    const startOfMonth = currentMonth.startOf("month");
    const endOfMonth = currentMonth.endOf("month");
    const days = [];
    let day = startOfMonth.startOf("week");

    while (day <= endOfMonth.endOf("week")) {
      days.push(day);
      day = day.add(1, "day");
    }

    return days;
  };

  const daysInMonth = generateDaysInMonth();

  return (
    <Box
      border={1}
      borderColor={theme.palette.border.main}
      borderRadius={isMobile ? 0 : 3}
      bgcolor={theme.palette.background.paper}
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={isMobile ? 1 : 2}
        position="sticky"
        top={0}
        bgcolor={theme.palette.background.paper}
        zIndex={1}
        borderBottom={1}
        borderColor={theme.palette.border.main}
        sx={{
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
        }}
      >
        <IconButton
          onClick={handlePrevMonth}
          size={isMobile ? "small" : "medium"}
        >
          <ArrowBackIcon fontSize={isMobile ? "small" : "medium"} />
        </IconButton>
        <Typography variant={isMobile ? "body2" : "h6"}>
          {currentMonth.format("MMMM YYYY")}
        </Typography>
        <Box alignItems="center">
          <IconButton
            onClick={handleNextMonth}
            size={isMobile ? "small" : "medium"}
          >
            <ArrowForwardIcon fontSize={isMobile ? "small" : "medium"} />
          </IconButton>
          <MenuIcon open={Boolean(anchorEl)} onClick={handleMenuOpen} />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleBulkSelectToggle}>
            {isBulkSelectMode ? "Disable Bulk Select" : "Enable Bulk Select"}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleViewAsClick}>View as athlete</MenuItem>
          <MenuItem onClick={handleViewAsEventGroupClick}>
            View as Event Group
          </MenuItem>
        </Menu>
      </Box>
      <Box
        position="sticky"
        top={isMobile ? "48px" : "64px"}
        bgcolor={theme.palette.background.paper}
        zIndex={1}
      >
        {isBulkSelectMode && (
          <Box display="flex" justifyContent="center" p={isMobile ? 1 : 2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBulkSelectComplete}
              disabled={selectedDates.length === 0}
              sx={{ mr: 1, fontSize: isMobile ? "0.7rem" : "0.875rem" }}
            >
              Add Workout
              <Badge
                badgeContent={selectedDates.length}
                color="secondary"
                sx={{ ml: 1 }}
              />
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancelBulkAdd}
              sx={{ fontSize: isMobile ? "0.7rem" : "0.875rem" }}
            >
              Cancel Bulk Add
            </Button>
          </Box>
        )}
      </Box>
      <Box p={isMobile ? 0 : 2}>
        <Grid
          container
          spacing={isMobile ? 0.0 : 0.0}
          border={isMobile ? "none" : `.5px solid ${theme.palette.border.main}`}
        >
          <Grid container item xs={12}>
            {["S", "M", "T", "W", "T", "F", "S"].map((dayName) => (
              <Grid
                item
                xs={12 / 7}
                key={dayName}
                border={`.5px solid ${theme.palette.border.main}`}
              >
                <Typography
                  variant={isMobile ? "caption" : "subtitle1"}
                  align="center"
                >
                  {dayName}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {daysInMonth
            .reduce((rows, day, index) => {
              if (index % 7 === 0) rows.push([]);
              rows[rows.length - 1].push(day);
              return rows;
            }, [])
            .map((week, weekIndex) => (
              <Grid
                container
                item
                xs={12}
                key={weekIndex}
                sx={{ display: "flex", alignItems: "stretch" }}
              >
                {week.map((day) => {
                  const dateString = day.format("YYYY-MM-DD");
                  return (
                    <Grid
                      item
                      xs={12 / 7}
                      key={dateString}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Day
                        day={day}
                        currentMonth={currentMonth}
                        onClick={() => handleDayClick(day)}
                        data={calanderData ? calanderData[dateString] : null}
                        isBulkSelected={selectedDates.includes(dateString)}
                        isMobile={isMobile}
                        sx={{ flexGrow: 1 }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ))}
        </Grid>
      </Box>
      {selectedDate && !isBulkSelectMode && isModalOpen && (
        <ModalComponent
          date={selectedDate}
          onClose={() => {
            setSelectedDate(null);
            setIsModalOpen(false);
          }}
        />
      )}
      {isBulkSelectMode && isModalOpen && (
        <ModalComponent
          dates={selectedDates}
          onClose={() => {
            setSelectedDates([]);
            setIsBulkSelectMode(false);
            setIsModalOpen(false);
          }}
        />
      )}
      {isSelectAthleteOpen && (
        <SelectAthlete
          open={isSelectAthleteOpen}
          onClose={() => setIsSelectAthleteOpen(false)}
          allAthletes={athletes || []}
          selectedAthletes={selectedAthleteIds}
          onToggleAthlete={handleToggleAthlete}
          onSave={handleSaveSelectedAthletes}
        />
      )}
      {isSelectEventGroupOpen && (
        <SelectEventGroup
          open={isSelectEventGroupOpen}
          onClose={() => setIsSelectEventGroupOpen(false)}
          eventGroups={eventGroups || []}
          selectedEventGroupIds={selectedEventGroupIds}
          onSave={handleSaveSelectedEventGroups}
        />
      )}
    </Box>
  );
};

export default Calander;
