import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ComponentWrapper from "../../../../components/utils/wrappers/componentWrapper";

const EventGroups = `https://imgur.com/oi6gDWD.png`;
const Roster = `https://imgur.com/MvEEzO1.png`;
const TrainingPlans = `https://imgur.com/W7fzowg.png`;
const Workouts = `https://imgur.com/BkYfwGv.png`;

const features = [
  {
    image: Roster,
    title: "Athlete Management",
    bullets: [
      "Team rosters",
      "TFFRS integration",
      "Dynamic workout calculations",
    ],
  },
  {
    image: EventGroups,
    title: "Event Groups",
    bullets: [
      "Event-based organization",
      "Specialized training",
      "Team optimization",
    ],
  },
  {
    image: Workouts,
    title: "Workout Planning",
    bullets: [
      "Custom templates",
      "Auto-calculated paces",
      "Workout categorization",
    ],
  },
  {
    image: TrainingPlans,
    title: "Training Plans",
    bullets: [
      "On-the-fly editing",
      "Auto-updating workouts",
      "Athlete plan viewing",
    ],
  },
];

const ExploreFeatures = () => {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [loadedImages, setLoadedImages] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    features.forEach((feature) => {
      const img = new Image();
      img.src = feature.image;
      img.onload = () => {
        setLoadedImages((prev) => ({ ...prev, [feature.image]: true }));
      };
    });
  }, []);

  const handlePrev = () => {
    setCurrentFeature((prev) => (prev === 0 ? features.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentFeature((prev) => (prev === features.length - 1 ? 0 : prev + 1));
  };

  return (
    <Box sx={{ width: "100%", margin: "auto", position: "relative" }}>
      <ComponentWrapper
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          position: "relative",
          width: "100%",
        }}
      >
        <IconButton
          onClick={handlePrev}
          sx={{
            position: "absolute",
            left: 10,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Box sx={{ padding: 2, width: "100%", textAlign: "center" }}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {features[currentFeature].title}
          </Typography>
          {loadedImages[features[currentFeature].image] ? (
            <img
              src={features[currentFeature].image}
              alt={features[currentFeature].title}
              style={{ width: "100%", height: "300px", objectFit: "contain" }}
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>Loading image...</Typography>
            </Box>
          )}
          <Box sx={{ mt: 1, width: "100%" }}>
            {features[currentFeature].bullets.map((bullet, idx) => (
              <Typography
                key={idx}
                variant="body2"
                sx={{
                  mb: 0.5,
                  fontSize: isMobile ? "0.75rem" : "inherit",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {bullet}
              </Typography>
            ))}
          </Box>
        </Box>
        <IconButton
          onClick={handleNext}
          sx={{
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </ComponentWrapper>
    </Box>
  );
};

export { ExploreFeatures };
