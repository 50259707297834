import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const RotatingIconButton = styled(IconButton)(({ theme, expanded }) => ({
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
}));

const CollapseIcon = ({ expanded, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip title={expanded ? "Collapse" : "Expand"} arrow>
      <RotatingIconButton
        onClick={onClick}
        expanded={expanded}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          '&:hover': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
      >
        <ExpandMoreIcon color={isHovered ? 'primary' : 'inherit'} />
      </RotatingIconButton>
    </Tooltip>
  );
};

export default CollapseIcon;
