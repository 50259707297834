import React, { useState, useContext } from 'react';
import { Box, Grid, Typography, TextField, Button } from '@mui/material';
import useApiService from '../../api/apiService';
import { AuthContext } from '../../context/authContext';
import loginTrack from './loginTrack.webp';
import { useNavigate, useLocation } from 'react-router-dom';

const InvitedPage = () => {
  const apiService = useApiService();
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const { mutate: resetPassword } = apiService.usePasswordReset();
  const { mutate: loginUser } = apiService.useLoginUser();

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSetPassword = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      console.log('Passwords do not match');
      return;
    }
    resetPassword({ token, password }, {
      onSuccess: () => {
        loginUser({ email, password }, {
          onSuccess: (response) => {
            const { user, token, type, team } = response.data;
            setTimeout(() => {
              login(user, token, type, team);
              navigate('/home');
            }, 500);
          },
          onError: (error) => {
            console.error('Login failed', error);
          }
        });
      },
      onError: (error) => {
        console.error('Password reset failed', error);
      }
    });
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: `url(${loginTrack})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => t.palette.background.default,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: (t) => `${t.palette.primary.main}50`,
          }}
        />
        <Box
          component="form"
          onSubmit={handleSetPassword}
          sx={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 400,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '24px',
            borderColor: (t) => `${t.palette.border.main}`,
            backgroundColor: (t) => `${t.palette.background.paperSecondary}`,
            zIndex: 1,
          }}
        >
          <Typography component="h1" variant="h5" sx={{ color: 'primary.main', fontFamily: 'Arial, sans-serif', mb: 2 }}>
            Welcome to Track Tools Pro
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Please set your password to complete your registration.
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={handlePasswordChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Set Password
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InvitedPage;
